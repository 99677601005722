import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormControlStatus,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { USER_CREDENTIALS_REG_EXP } from '@core/hubconfig';
import { patternValidator } from '@shared/validators/patternValidator';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AddOrEditUserFormStatus, ConfigHubAddOrEditUserForm } from './hubAddOrEditUserForm';

@Component({
  selector: 'app-hub-add-or-edit-user-form',
  templateUrl: './hub-add-or-edit-user-form.component.html',
  styleUrls: ['./hub-add-or-edit-user-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class HubAddOrEditUserFormComponent implements OnInit, OnDestroy {
  @Output() formStatusChange: EventEmitter<AddOrEditUserFormStatus>;

  @Input() config: ConfigHubAddOrEditUserForm = {
    state: 'ADD-USER',
  };

  @Input() set IsEmailAlreadyExistsError(newVal: boolean) {
    this.userForm?.controls.email.setErrors({ emailAlreadyExists: newVal });
  }

  lIsEmailAlreadyExistsError?: boolean;

  HubAvailableRoles: { title: string; value: string }[];

  userForm!: UntypedFormGroup;

  private destroy$: Subject<void>;

  constructor(private fbBuilder: UntypedFormBuilder) {
    this.destroy$ = new Subject();
    this.formStatusChange = new EventEmitter();
    this.lIsEmailAlreadyExistsError = false;
    this.HubAvailableRoles = [
      { title: 'Administrator', value: 'admin' },
      { title: 'Contributor', value: 'contributor' },
      { title: 'Member', value: 'member' },
    ];
  }

  ngOnInit(): void {
    const formControls = {
      firstName: ['', [Validators.required, Validators.pattern(USER_CREDENTIALS_REG_EXP)]],
      lastName: ['', [Validators.required, Validators.pattern(USER_CREDENTIALS_REG_EXP)]],
      email: ['', [Validators.required, Validators.email]],
      role: ['contributor', [Validators.required]],
    };

    if (this.config.state === 'ADD-USER') {
      formControls['password'] = [
        '',
        Validators.compose([
          Validators.required,
          patternValidator(/\d/, { hasNumber: true }),
          patternValidator(/[A-Z]/, { hasCapitalCase: true }),
          patternValidator(/[a-z]/, { hasSmallCase: true }),
          Validators.minLength(8),
        ]),
      ];
    }

    this.userForm = this.fbBuilder.group(formControls);
    this.userForm.statusChanges
      .pipe(
        map((status: FormControlStatus) => status === 'VALID'),
        takeUntil(this.destroy$),
      )
      .subscribe((isValidStatus) => {
        this.formStatusChange.emit({ isValid: isValidStatus, value: this.userForm.value });
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
