import { AbstractControl } from '@angular/forms';

export const lightColorValidator = (control: AbstractControl): { lightColor: boolean } | null => {
  const color = control.value;
  if (color && isLightColor(color)) {
    return { lightColor: true };
  }
  return null;
};

function isLightColor(color: string): boolean {
  const rgb = hexToRgb(color);
  // Calculate the brightness using the relative luminance formula
  const brightness = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
  // Return true if the brightness is above a certain threshold
  return brightness > 200;
}

function hexToRgb(hex: string): { r: number; g: number; b: number } {
  hex = hex.replace(/^#/, '');
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return { r, g, b };
}
