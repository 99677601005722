import { DeployedProjectResponse } from '@core/interfaces/project';

export interface Agent {
  id: string;
  name: string;
  description: string;
  modifiedAt: string;
  modifiedBy: string;
  isDefault: boolean;
  isDeployed: boolean;
  deploymentEnv: string;
  invokeUrl: string;
  status: AgentStatus;
  showFullDescription: boolean;
  connectivity: AgentConnectivity;
}

export interface AgentConnectivity {
  timestamp?: Date;
  state?: AgentConnectivityState;
}

export enum AgentConnectivityState {
  SUCCEED = 'SUCCEED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  ERROR = 'ERROR',
}

export interface DeployedAgentResponse extends DeployedProjectResponse {
  deploymentUrl: string;
}

export enum AgentStatus {
  DEPLOY_PENDING = 'DEPLOY_PENDING',
  DELETE_PENDING = 'DELETE_PENDING',
  DEPLOYED = 'DEPLOYED',
  UN_DEPLOYED = 'UN_DEPLOYED',
  ERROR = 'ERROR',
}

export enum HubDesignerAgent {
  NONE = 'NONE',
  TEST = 'TEST',
  PROD = 'PROD',
}
