import { environment } from '@env';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { HubAuthService } from '@core/services/hub-auth/hub-auth.service';
import { HubDestroyService } from '@core/services/hub-destroy/hub-destroy.service';
import { TenantsService } from '@core/services/tenants/tenants.service';
import { ToastsService } from '@core/services/toasts/toasts.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { UserState } from '@core/store/user/user.state';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-hub-activate-license-modal',
  templateUrl: './hub-activate-license-modal.component.html',
  styleUrls: ['./hub-activate-license-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HubDestroyService],
  standalone: false,
})
export class HubActivateLicenseModalComponent {
  @Input() upgradeLicense = false;
  form = new UntypedFormGroup({
    licenseKey: new UntypedFormControl('', [Validators.required]),
  });

  licenseKeyActivationFailed = false;
  isEnterpriseOrLightMode = environment.enterprise || environment.light;

  protected readonly store = inject(Store);

  constructor(
    private hubAuthService: HubAuthService,
    private toastsService: ToastsService,
    private activeModal: NgbActiveModal,
    private tenantsService: TenantsService,
    private changeDetectorRef: ChangeDetectorRef,
    private hubDestroyService: HubDestroyService,
  ) {}

  onLogout() {
    if (this.upgradeLicense) {
      this.activeModal.dismiss();
    } else {
      this.hubAuthService.logout();
    }
  }

  onSubmit() {
    if (this.form.valid) {
      this.licenseKeyActivationFailed = false;
      const licenseKey = this.form.get('licenseKey')?.value;
      const user = this.store.selectSnapshot(UserState.user);
      this.tenantsService
        .checkLicenseKey(user.tenantId, licenseKey)
        .pipe(
          finalize(() => {
            this.changeDetectorRef.detectChanges();
          }),
          catchError((err) => {
            this.licenseKeyActivationFailed = true;
            this.changeDetectorRef.detectChanges();
            return throwError(err);
          }),
          this.hubDestroyService.takeUntilDestroy(),
        )

        .subscribe(() => {
          this.activeModal.close(licenseKey);
          this.toastsService.show('License activated successfully');
        });
    }
  }
}
