import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Setting } from '@core/interfaces/settings';
import { environment } from '@env';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private readonly settingsServiceUrl = `${environment.BACKEND_DOMAIN}/api/v1/settings`;

  constructor(private http: HttpClient) {}

  updateOne(setting: Setting): Observable<void> {
    return this.http.put<void>(`${this.settingsServiceUrl}/${setting.key}`, {
      value: setting.value,
    });
  }

  readOne(key: string): Observable<Setting> {
    return this.http.get<Setting>(`${this.settingsServiceUrl}/${key}`);
  }
}
