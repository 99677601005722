<div class="row pl-md-4 hub-pl-0">
  <div class="hub-d-flex hub-flex-column col-2 tabs-wrapper">
    <hub-button
      class="hub-w-100 team-tab-btn hub-p-relative"
      customClass="hub-p-1 hub-text-left hub-w-100"
      [class.active]="activeTab === 'members'"
      type="clean"
      (clicked)="onChangeTab('members')">
      Members
    </hub-button>
    @if (!isLicensePortal) {
      <hub-button
        class="hub-w-100 team-tab-btn hub-p-relative"
        customClass="hub-p-1 hub-text-left hub-w-100"
        [class.active]="activeTab === 'groups'"
        type="clean"
        (clicked)="onChangeTab('groups')">
        Groups
      </hub-button>
    }
  </div>
  @if (activeTab === 'members') {
    <div class="col-10">
      <div class="col-12 hub-d-flex hub-align-items-center hub-justify-content-between hub-mb-1">
        <h1 class="hub-d-flex hub-align-items-center hub-text-lighter">
          {{ usersList().length }}
          <hub-icon [icon]="'hub-mx-0-5 icon-user'" />
          <span class="hub-fs-16 hub-fw-bold"> Members </span>
        </h1>

        @if (isAdminUser()) {
          <div *hubIsLicenseActive>
            @if (IS_ENTERPRISE_OR_LITE) {
              <hub-button
                class="hub-mr-0-5"
                (clicked)="onPullExternalUsers()"
                [isLoading]="isPullingProcess">
                Pull External Users
              </hub-button>
            }
            <hub-button (clicked)="openUserModal()">
              {{ IS_ENTERPRISE_OR_LITE ? 'Add User' : 'Invite User' }}
            </hub-button>
          </div>
        }
      </div>

      <div class="members-wrapper col-12" hubInfiniteScroll (scrolled)="onScroll()">
        <table class="hub-table table table-striped" aria-describedby="users-table">
          <thead>
            <tr hub-table-header [list]="memberHeaders" (sort)="onSort($event)"></tr>
          </thead>
          <tbody>
            @for (user of usersList(); track user.id) {
              <tr>
                <td>
                  <div class="hub-d-flex hub-align-items-center hub-fs-14">
                    {{ user.fullName }}
                  </div>
                </td>
                <td>
                  <div class="hub-d-flex hub-align-items-center hub-fs-14">
                    {{ user.email }}
                    <hub-button
                      class="hub-p-0 hub-ml-0-5"
                      type="iconPrimary"
                      [ngbTooltip]="'Copy email'"
                      icon="copy-email-icon icon-copy"
                      [copyToClipboardText]="user.email" />
                  </div>
                </td>
                <td>
                  <div class="hub-d-flex hub-align-items-center hub-fs-14 hub-tt-capitalize">
                    {{ user.role }}
                  </div>
                </td>
                <td>
                  <div class="hub-d-flex hub-align-items-center hub-justify-content-end hub-fs-14">
                    <hub-actions-menu
                      *hubIsLicenseActive
                      [actions]="
                        isAdminUser()
                          ? user.roles[0] === UserRoles.ADMIN
                            ? adminActions
                            : memberActions
                          : []
                      "
                      (selectedAction)="onSelectedActionInMenu($event, user)" />
                  </div>
                </td>
              </tr>
            }
          </tbody>
        </table>
        <div class="hub-d-flex">
          <hub-button class="hub-mx-auto hub-my-1-5" type="link" [appScrollToElement]>
            Back to the Top
          </hub-button>
        </div>
      </div>
    </div>
  }

  @if (activeTab === 'groups') {
    <div class="col-10">
      <div class="col-12 hub-d-flex hub-align-items-center hub-mb-1">
        <h1 class="hub-d-flex hub-align-items-center hub-text-lighter">
          {{ tenantGroups.length }}
          <hub-icon [icon]="'hub-mx-0-5 icon-user'" />
          <span class="hub-fs-16 hub-fw-bold"> Groups </span>
        </h1>

        @if (isAdminUser()) {
          <hub-button *hubIsLicenseActive class="hub-ml-auto" (clicked)="onAddGroup()">
            Add Group
          </hub-button>
        }
      </div>

      <div class="hub-overflow-auto col-12">
        <table class="hub-table table table-striped" aria-describedby="groups-table">
          <thead>
            <tr hub-table-header [list]="groupHeader" (sort)="onSort($event)"></tr>
          </thead>
          <tbody>
            @for (group of tenantGroups; track group.id) {
              <tr>
                <td>
                  <div class="hub-d-flex hub-align-items-center hub-fs-14">
                    {{ group.name }}
                  </div>
                </td>
                <td>
                  <div class="hub-d-flex hub-align-items-center hub-fs-14">
                    {{ group.description }}
                  </div>
                </td>
                <td>
                  <div class="hub-d-flex hub-align-items-center hub-fs-14 hub-tt-capitalize">
                    {{ group.modifiedAt | date: 'd MMM y' }}
                  </div>
                </td>
                <td>
                  <div class="hub-d-flex hub-align-items-center hub-justify-content-end hub-fs-14">
                    <hub-actions-menu
                      [actions]="isAdminUser() ? groupActions : []"
                      (selectedAction)="onSelectedGroupActionInMenu($event, group)" />
                  </div>
                </td>
              </tr>
            }
          </tbody>
        </table>
        <div class="hub-d-flex">
          <hub-button class="hub-mx-auto hub-my-1-5" type="link" [appScrollToElement]>
            Back to the Top
          </hub-button>
        </div>
      </div>
    </div>
  }
</div>
