import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  signal,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { HubDestroyService } from '@core/services/hub-destroy/hub-destroy.service';
import { ToastsService } from '@core/services/toasts/toasts.service';
import { UsersService } from '@core/services/users/users.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { patternValidator } from '@shared/validators/patternValidator';
import { catchError, map } from 'rxjs/operators';
import { GENERIC_ERROR_MESSAGE, IS_HUB_ENTERPRISE_OR_HUB_LITE } from '@core/hubconfig';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-reset-user-password-modal',
  templateUrl: './reset-user-password-modal.component.html',
  styleUrls: ['./reset-user-password-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HubDestroyService],
  standalone: false,
})
export class ResetUserPasswordModalComponent {
  userId = '';
  userEmail = '';
  isLoading = signal(false);

  resetUserPasswordForm = new UntypedFormGroup({
    password: new UntypedFormControl(
      '',
      Validators.compose([
        Validators.required,
        patternValidator(/\d/, { hasNumber: true }),
        patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        patternValidator(/[a-z]/, { hasSmallCase: true }),
        Validators.minLength(8),
      ]),
    ),
  });

  private activeModal = inject(NgbActiveModal);
  private toastService = inject(ToastsService);
  private usersService = inject(UsersService);
  private changeDetectorRef = inject(ChangeDetectorRef);
  private readonly hubDestroyService = inject(HubDestroyService);

  onSubmit() {
    if (this.resetUserPasswordForm.valid) {
      this.isLoading.set(true);
      this.changeDetectorRef.markForCheck();

      const $resetPassword = IS_HUB_ENTERPRISE_OR_HUB_LITE
        ? this.usersService.changePasswordEnterprise(
            this.userId,
            this.resetUserPasswordForm.get('password')?.value,
          )
        : this.usersService
            .updatePasswordByAdmin(this.userId, this.resetUserPasswordForm.value)
            .pipe(map(() => void 0));

      $resetPassword
        .pipe(
          catchError((err) => {
            this.toastService.show(GENERIC_ERROR_MESSAGE);
            this.isLoading.set(false);
            return throwError(() => err);
          }),
          this.hubDestroyService.takeUntilDestroy(),
        )
        .subscribe(() => {
          this.toastService.show('Password reset successfully');
          this.activeModal.close();
        });
    }
  }

  dismissModal() {
    this.activeModal.dismiss();
  }
}
