import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { LicenseLimitations } from '@core/interfaces/production-license';
import { ProjectVersionLicense } from '@core/interfaces/project';
import { HubDestroyService } from '@core/services/hub-destroy/hub-destroy.service';
import { HubFileService } from '@core/services/hub-file-helper/hub-file.service';
import { LicensesService } from '@core/services/licenses/licenses.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HubUploadFileComponent } from '@shared/components/hub-upload-file/hub-upload-file.component';
import {
  Observable,
  Subject,
  UnaryFunction,
  catchError,
  filter,
  finalize,
  map,
  mergeMap,
  of,
  pipe,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';

@Component({
  selector: 'app-upload-license',
  templateUrl: './upload-license.component.html',
  styleUrl: './upload-license.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HubDestroyService],
  standalone: false,
})
export class UploadLicenseComponent {
  @ViewChild(HubUploadFileComponent) hubUploadFileComponent!: HubUploadFileComponent;
  isUploadLicenseRequestView = false;
  fileContent?: Record<string, unknown> | ProjectVersionLicense;
  showErrorMessage = false;
  loadingProgress: number | null = 0;
  isSaveProcess = false;
  licenseLimitations?: LicenseLimitations;
  readonly cancel$ = new Subject<void>();

  constructor(
    private activeModal: NgbActiveModal,
    private changeDetectorRef: ChangeDetectorRef,
    private licensesService: LicensesService,
    private hubDestroyService: HubDestroyService,
    private hubFileService: HubFileService,
  ) {}

  onClose() {
    this.activeModal.dismiss();
  }

  async onFileUploaded(files?: File[]): Promise<void> {
    this.loadingProgress = 0;
    this.showErrorMessage = false;
    this.fileContent = undefined;
    if (files) {
      try {
        this.fileContent = JSON.parse(await files[0].text());
      } catch (_) {
        this.fileContent = undefined;
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  onCancelUpload() {
    this.cancel$.next();
    this.loadingProgress = 0;
    this.fileContent = undefined;
    this.isSaveProcess = false;
    this.hubUploadFileComponent.clearFiles();
    this.hubUploadFileComponent.resetErrors();
    this.changeDetectorRef.markForCheck();
  }

  save() {
    if (this.fileContent) {
      this.isSaveProcess = true;
      const request$ = this.isUploadLicenseRequestView
        ? this.licensesService.uploadLicenseRequest(this.fileContent as ProjectVersionLicense)
        : this.licensesService.uploadLicense(this.fileContent as Record<string, unknown>);
      request$
        .pipe(
          this.reportProgress(),
          this.filterOutProgressEvents(),
          map((event) => event.body),
          mergeMap((response) => {
            if (this.isUploadLicenseRequestView) {
              this.licenseLimitations = (
                response as unknown as { result: LicenseLimitations }
              ).result;
              if (
                this.licenseLimitations.METHODS === 0 &&
                this.licenseLimitations.PREMIUM_METHODS === 0 &&
                this.licenseLimitations.SCREENS === 0
              ) {
                return this.issueLicense();
              } else {
                return of(response);
              }
            } else {
              return of(response);
            }
          }),
          catchError((error) => {
            this.showErrorMessage = true;
            this.isSaveProcess = false;
            this.changeDetectorRef.detectChanges();
            return throwError(() => error);
          }),
          this.hubDestroyService.takeUntilDestroy(),
          takeUntil(this.cancel$),
        )
        .subscribe(() => {
          this.isSaveProcess = false;
          if (!this.isUploadLicenseRequestView) {
            this.activeModal.close();
          }
        });
    }
  }

  onIssueLicense() {
    this.issueLicense().subscribe();
  }

  private issueLicense() {
    this.isSaveProcess = true;
    let isError = false;
    return this.licensesService.issueLicense(this.fileContent as ProjectVersionLicense).pipe(
      catchError((error) => {
        this.showErrorMessage = true;
        this.isSaveProcess = false;
        isError = true;
        return throwError(() => error);
      }),
      finalize(() => {
        if (!isError) {
          this.isSaveProcess = false;
          this.activeModal.close();
        }
        this.changeDetectorRef.detectChanges();
      }),
    );
  }

  private reportProgress(): UnaryFunction<
    Observable<HttpEvent<unknown>>,
    Observable<HttpEvent<unknown>>
  > {
    return pipe(
      tap(
        this.hubFileService.getHttpEventHandler({
          progress: (progress) => {
            this.loadingProgress = progress;
            this.changeDetectorRef.markForCheck();
          },
          response: () => {
            this.loadingProgress = 100;
            this.changeDetectorRef.markForCheck();
          },
        }),
      ),
    );
  }

  private filterOutProgressEvents(): UnaryFunction<
    Observable<HttpEvent<unknown>>,
    Observable<HttpResponse<void>>
  > {
    return pipe(
      filter(
        (event: HttpEvent<unknown>): event is HttpResponse<void> =>
          event.type === HttpEventType.Response,
      ),
    );
  }
}
