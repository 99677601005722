<div class="modal-header hub-flex-column hub-mb-3 hub-pb-0">
  <h3 class="modal-title">Edit Group {{ group.name }}</h3>
  @if (isUnexpectedGeneralError) {
    <p class="hub-text-danger hub-mb-0">
      Failed to edit Group. Please contact support&#64;openlegacy.com
    </p>
  }
</div>

<form [formGroup]="form" (ngSubmit)="onEdit()">
  <div class="modal-body">
    <hub-input-form-control
      formControlName="name"
      label="Group Name"
      [isRequired]="true"
      [validationMessages]="'Please provide group name.'">
    </hub-input-form-control>

    <hub-input-form-control
      formControlName="description"
      label="Group Description"
      [hasMaxLengthCounter]="true"
      [maxLength]="descriptionMaxLength"
      [validationMessages]="'Please provide group description.'">
    </hub-input-form-control>
  </div>

  <div
    class="modal-footer hub-d-flex hub-justify-content-end hub-pt-0 hub-tooltip-mw-15rem hub-mt-3">
    <button
      type="button"
      class="btn btn-primary-outline hub-m-0 hub-mr-1"
      (click)="activeModal.dismiss()">
      Cancel
    </button>
    <button
      type="submit"
      class="btn btn-primary"
      [class.disabled]="form.invalid"
      [ngbTooltip]="form.invalid ? 'Please fill fields to edit group' : ''">
      @if (isLoading) {
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      }
      Edit
    </button>
  </div>
</form>
