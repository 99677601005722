<div class="modal-header hub-flex-column hub-mb-3 hub-pb-0">
  <h3 class="hub-mb-0-5">Edit User Details For</h3>
  <p class="hub-text-message user-email">{{ user.email }}</p>
  <p class="hub-text-danger hub-opacity-0 hub-mb-0" [class.hub-opacity-100]="errMessage">
    {{ errMessage || 'Placeholder' }}
  </p>
</div>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <div class="hub-d-flex hub-flex-nowrap">
      <hub-input-form-control
        class="w-50 hub-mr-0-5"
        formControlName="firstName"
        label="First Name"
        [isRequired]="true"
        [validationMessages]="'Please provide first name.'">
      </hub-input-form-control>

      <hub-input-form-control
        class="w-50"
        formControlName="lastName"
        label="Last Name"
        [isRequired]="true"
        [validationMessages]="'Please provide last name.'">
      </hub-input-form-control>
    </div>

    <div class="form-group">
      <hub-dropdown-form-field
        formControlName="role"
        [options]="options"
        [clearable]="false"
        [label]="'Role'">
      </hub-dropdown-form-field>
    </div>
  </div>

  <div class="modal-footer hub-d-flex hub-justify-content-end hub-pt-0 hub-mt-3">
    <button
      type="button"
      class="hub-w-8-5 btn btn-primary-outline hub-mr-1"
      data-dismiss="modal"
      (click)="onCancel()">
      Cancel
    </button>
    <button
      type="submit"
      class="hub-w-8-5 btn btn-primary"
      data-dismiss="modal"
      [disabled]="form.invalid">
      Update
    </button>
  </div>
</form>
