@if (isLoaded) {
  @if (labelsList.length > 0) {
    <h3 class="hub-fw-500 hub-ff-roboto hub-mb-0-5">Labels</h3>
    <p>
      Labels can be used to tag projects. Admins can create/edit labels. Users may assign labels.
    </p>
    @if (isAdminUser()) {
      <hub-button customClass="hub-mt-0-25 hub-mb-1-5" (clicked)="createLabel()">
        Create new label
      </hub-button>
    }

    <div class="col-xl-7 hub-p-0 labels-wrapper">
      @for (label of labelsList; track labelsList) {
        <hub-card
          [sectionSizes]="{
            HEADER: 'col',
            DESCRIPTION: '',
            ADDITIONAL_INFO: 'col-auto',
          }"
          [header]="'Associated ' + (label.additionalInfo?.numOfProjects || 0) + ' projects'"
          [borderLeft]="false"
          headerCustomClass="hub-fs-14 hub-mb-0">
          <div class="label--container" header>
            <div class="label--color-circle" [style.background-color]="label.color"></div>
            <p class="hub-fs-18 hub-pl-0-5">{{ label.name }}</p>
          </div>

          @if (isAdminUser()) {
            <div additionalInfo>
              <hub-button
                icon="icon-edit"
                customClass="hub-p-0-25"
                type="iconPrimary"
                (clicked)="updateLabel(label)"></hub-button>
              <hub-button
                aria-placeholder="Delete Label"
                icon="icon-delete"
                customClass="hub-p-0-25"
                type="iconPrimary"
                (clicked)="deleteLabel(label)"></hub-button>
            </div>
          }
        </hub-card>
      }
    </div>
  } @else {
    <div
      class="hub-d-flex hub-flex-column empty-view-container hub-align-items-center hub-w-100 hub-mt-2-5">
      <hub-icon size="custom" [icon]="'icon-folder hub-fs-112 hub-mb-1-5'"></hub-icon>
      <h3 class="hub-fw-500 hub-ff-roboto hub-mb-1">There are no Labels yet.</h3>
      @if (isAdminUser()) {
        <div class="hub-d-flex hub-text-center">
          <p class="hub-mb-0">Please click</p>
          <hub-button
            customClass="hub-py-0 hub-px-0-25 hub-border-0"
            type="link"
            (clicked)="createLabel()">
            Create new label
          </hub-button>
          <p class="hub-mb-0">button to</p>
        </div>
        <p class="hub-text-center">create new labels.</p>
        <hub-button customClass="hub-mt-1-5" (clicked)="createLabel()">
          Create new label
        </hub-button>
      } @else {
        <p class="hub-text-center">Please Contact your account admin to<br />add new Labels.</p>
      }
    </div>
  }
}
