import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { USER_CREDENTIALS_REG_EXP } from '@core/hubconfig';
import { UserTenantsAuth } from '@core/interfaces/user';
import { HubDestroyService } from '@core/services/hub-destroy/hub-destroy.service';
import { ToastsService } from '@core/services/toasts/toasts.service';
import { UsersService } from '@core/services/users/users.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { DropdownValue } from '@shared/components/hub-dropdown-form-field/hub-dropdown';
import { PermissionsService } from '@core/services/permissions/permissions.service';

@Component({
  selector: 'app-edit-user-modal',
  templateUrl: './edit-user-modal.component.html',
  styleUrls: ['./edit-user-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HubDestroyService],
  standalone: false,
})
export class EditUserModalComponent implements OnInit {
  errMessage: string;

  user!: UserTenantsAuth;

  form!: UntypedFormGroup;

  options: DropdownValue[];

  constructor(
    private usersService: UsersService,
    private permissionsService: PermissionsService,
    private activeModal: NgbActiveModal,
    private toastService: ToastsService,
    private changeDetectorRef: ChangeDetectorRef,
    private readonly hubDestroyService: HubDestroyService,
  ) {
    this.errMessage = '';
    this.options = [];
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      firstName: new UntypedFormControl(this.user.firstName, [
        Validators.required,
        Validators.pattern(USER_CREDENTIALS_REG_EXP),
      ]),
      lastName: new UntypedFormControl(this.user.lastName, [
        Validators.required,
        Validators.pattern(USER_CREDENTIALS_REG_EXP),
      ]),
      role: new UntypedFormControl(this.user.roles[0]),
    });
    this.form.markAllAsTouched();
  }

  onSubmit(): void {
    if (this.form.valid) {
      forkJoin([
        this.usersService.updateByAdmin(this.user.id, {
          firstName: this.form.get('firstName')?.value,
          lastName: this.form.get('lastName')?.value,
        }),
        this.permissionsService.updateUserRoleByAdmin(this.user.id, {
          role: this.form.get('role')?.value,
        }),
      ])
        .pipe(
          catchError((err) => {
            this.errMessage = err.error.detailedMessage;
            this.changeDetectorRef.detectChanges();
            return throwError(err);
          }),
          takeUntil(this.hubDestroyService.destroy$),
        )
        .subscribe(([updatedUser]) => {
          updatedUser.app_metadata.roles = [this.form.get('role')?.value];
          this.toastService.show(`Successfully updated ${updatedUser.email} user details`);
          this.activeModal.close(updatedUser);
        });
    }
  }

  onCancel(): void {
    this.activeModal.dismiss();
  }
}
