import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TenantGroup } from '@core/interfaces/permissions';
import { UserTenantsAuth } from '@core/interfaces/user';
import { HubDestroyService } from '@core/services/hub-destroy/hub-destroy.service';
import { PermissionsService } from '@core/services/permissions/permissions.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DropdownValue } from '@shared/components/hub-dropdown-form-field/hub-dropdown';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-manage-users-in-group',
  templateUrl: './manage-users-in-group.component.html',
  styleUrls: ['./manage-users-in-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HubDestroyService],
  standalone: false,
})
export class ManageUsersInGroupComponent implements OnInit {
  form!: FormGroup;

  usersInGroup: UserTenantsAuth[];

  tenantUsers: UserTenantsAuth[];

  isLoading: boolean;

  isUnexpectedGeneralError: boolean;

  group!: TenantGroup;

  dataForDropdown: DropdownValue[];

  isLoaded = false;

  constructor(
    public activeModal: NgbActiveModal,
    private permissionsService: PermissionsService,
    private changeDetectorRef: ChangeDetectorRef,
    private readonly hubDestroyService: HubDestroyService,
  ) {
    this.tenantUsers = [];
    this.usersInGroup = [];
    this.dataForDropdown = [];

    this.isLoading = false;
    this.isLoaded = false;
    this.isUnexpectedGeneralError = false;
  }

  ngOnInit(): void {
    for (const user of this.tenantUsers) {
      this.dataForDropdown.push({
        label: `${user.firstName}  ${user.lastName}`,
        value: user.id,
        disabled: false,
      });
    }
    this.permissionsService
      .getGroupUsers(this.group.id)
      .pipe(takeUntil(this.hubDestroyService.destroy$))
      .subscribe((users) => {
        this.usersInGroup = users;
        this.form = new FormGroup({
          users: new FormControl(
            users.map(({ id }) => id),
            [Validators.required],
          ),
        });
        this.isLoaded = true;
        this.changeDetectorRef.detectChanges();
      });
  }

  onSave() {
    const resultUserIds = this.form.value.users;
    const tenantUserIds = this.tenantUsers.map((user) => user.id);
    const alreadyInGroupUserIds = this.usersInGroup.map((user) => user.id);
    const userIdsToAdd = tenantUserIds.filter(
      (id: string) => !alreadyInGroupUserIds.includes(id) && resultUserIds.includes(id),
    );
    const userIdsToRemove = tenantUserIds.filter(
      (id: string) => alreadyInGroupUserIds.includes(id) && !resultUserIds.includes(id),
    );

    const requests: Array<Observable<void>> = [];
    if (userIdsToAdd.length > 0) {
      requests.push(this.permissionsService.addGroupUsers(this.group.id, { users: userIdsToAdd }));
    }
    if (userIdsToRemove.length > 0) {
      requests.push(
        this.permissionsService.removeGroupUsers(this.group.id, { users: userIdsToRemove }),
      );
    }

    if (requests.length > 0) {
      this.isUnexpectedGeneralError = false;
      this.isLoading = true;
      forkJoin(requests)
        .pipe(
          catchError((err) => {
            this.isUnexpectedGeneralError = true;
            this.isLoading = false;
            return throwError(() => err);
          }),
          takeUntil(this.hubDestroyService.destroy$),
        )
        .subscribe(() => {
          this.activeModal.close();
        });
    } else {
      this.activeModal.close();
    }
  }
}
