<div class="manage-users-in-group-modal modal-header hub-flex-column hub-mb-3 hub-pb-0">
  <h3 class="modal-title">Manage Group {{ group.name }}</h3>
  @if (isUnexpectedGeneralError) {
    <p class="hub-text-danger hub-mb-0">
      Failed to add Group. Please contact support&#64;openlegacy.com
    </p>
  }
</div>

@if (isLoaded) {
  <form [formGroup]="form" (ngSubmit)="onSave()">
    <div class="modal-body">
      <label class="hub-fw-bolder">Group Users</label>

      <hub-dropdown-form-field
        [clearable]="false"
        [multiple]="true"
        [options]="dataForDropdown"
        formControlName="users">
      </hub-dropdown-form-field>
    </div>

    <div
      class="modal-footer hub-d-flex hub-justify-content-end hub-pt-0 hub-tooltip-mw-15rem hub-mt-3">
      <button
        type="button"
        class="btn btn-primary-outline hub-m-0 hub-mr-1"
        (click)="activeModal.dismiss()">
        Cancel
      </button>
      <button type="submit" class="btn btn-primary">
        @if (isLoading) {
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        }
        Save
      </button>
    </div>
  </form>
}
