import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { HubDatesHelperService } from '@core/services/hub-dates-helper/hub-dates-helper.service';
import { HubDestroyService } from '@core/services/hub-destroy/hub-destroy.service';
import { TenantsStoreService } from '@core/services/tenants/tenants-store.service';

@Directive({
  selector: '[hubIsLicenseActive]',
  standalone: true,
  providers: [HubDestroyService],
})
export class IsLicenseActiveDirective implements OnInit {
  @Input({
    alias: 'hubIsLicenseActive',
  })
  // needed to fix compiler type error in case usage without param passing (e.g. *hubIsLicenseActive - passed empty string)
  set gracePeriod(value: boolean | string) {
    this.considerGracePeriod = typeof value === 'string' ? true : value;
  }

  private considerGracePeriod = true;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainerRef: ViewContainerRef,
    private hubDestroyService: HubDestroyService,
    private tenantsStoreService: TenantsStoreService,
    private dateHelperService: HubDatesHelperService,
  ) {}

  ngOnInit() {
    this.tenantsStoreService.license$
      .pipe(this.hubDestroyService.takeUntilDestroy())
      .subscribe((license) => {
        if (license) {
          const remainingDaysOfLicense = this.dateHelperService.differenceInDays(
            new Date(license.expirationDate),
            new Date(),
          );

          const isExpired = remainingDaysOfLicense < 0;
          const isExpiredGracePeriod = license.gracePeriodDays * -1 > remainingDaysOfLicense;

          const isActive = !isExpired || (this.considerGracePeriod && !isExpiredGracePeriod);

          this.viewContainerRef.clear();

          if (isActive) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
          }
        }
      });
  }
}
