import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { User } from '@core/interfaces/user';
import { HubDestroyService } from '@core/services/hub-destroy/hub-destroy.service';
import { UsersService } from '@core/services/users/users.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  AddOrEditUserFormStatus,
  HubAddOrEditUserForm,
} from '@shared/components/hub-add-or-edit-user-form/hubAddOrEditUserForm';
import { throwError } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-add-user-to-tenant-account-modal',
  templateUrl: './add-user-to-tenant-account-modal.component.html',
  styleUrls: ['./add-user-to-tenant-account-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HubDestroyService],
  standalone: false,
})
export class AddUserToTenantAccountModalComponent {
  @Input() isInvite: boolean = false;
  @Input() isSSO: boolean = false;

  isEmailAlreadyExists: boolean;

  isUnexpectedGeneralError: boolean;

  isLoading: boolean;

  currentUserFormStatus: AddOrEditUserFormStatus;

  constructor(
    public activeModal: NgbActiveModal,
    private usersService: UsersService,
    private changeDetectorRef: ChangeDetectorRef,
    private readonly hubDestroyService: HubDestroyService,
  ) {
    this.currentUserFormStatus = { isValid: false, value: {} as HubAddOrEditUserForm };
    this.isEmailAlreadyExists = false;
    this.isUnexpectedGeneralError = false;
    this.isLoading = false;
  }

  onUserFormStatusChange(newStatus: AddOrEditUserFormStatus) {
    this.currentUserFormStatus = newStatus;
  }

  onSubmit(isFormValid: boolean, formValues: HubAddOrEditUserForm) {
    const { role, ...newUser } = formValues;

    if (isFormValid) {
      // TODO: research why @input is not triggered when value is the same, isEmailAlreadyExists = false force to run the setter
      this.isEmailAlreadyExists = false;
      this.isLoading = true;

      const observable$ = this.isInvite
        ? this.usersService.inviteUserToAccount({ ...newUser } as User, role, this.isSSO)
        : this.usersService.addUserToAccount({ ...newUser, roles: [role] } as User);

      observable$
        .pipe(
          catchError((err) => {
            if (err.status === 409) {
              this.isEmailAlreadyExists = true;
            } else {
              this.isUnexpectedGeneralError = true;
            }
            return throwError(err);
          }),
          finalize(() => {
            this.isLoading = false;
            this.changeDetectorRef.detectChanges();
          }),
          takeUntil(this.hubDestroyService.destroy$),
        )
        .subscribe((newTenantUser) => {
          this.activeModal.close(newTenantUser);
        });
    }
  }
}
