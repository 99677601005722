import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { LicenseLimitations } from '@core/interfaces/production-license';
import { HubDestroyService } from '@core/services/hub-destroy/hub-destroy.service';
import { LicensesService } from '@core/services/licenses/licenses.service';
import { ToastsService } from '@core/services/toasts/toasts.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, takeUntil, throwError } from 'rxjs';

@Component({
  selector: 'app-extend-project-license',
  templateUrl: './extend-project-license.component.html',
  styleUrl: './extend-project-license.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HubDestroyService],
  standalone: false,
})
export class ExtendProjectLicenseComponent {
  licenseLimitations?: LicenseLimitations;
  errorMessage = '';
  licenseId = '';

  isExtendProcess = false;

  constructor(
    private activeModal: NgbActiveModal,
    private changeDetectorRef: ChangeDetectorRef,
    private licensesService: LicensesService,
    private hubDestroyService: HubDestroyService,
    private toastService: ToastsService,
  ) {}

  onClose() {
    this.activeModal.dismiss();
  }

  onExtend() {
    this.isExtendProcess = true;
    this.licensesService
      .extendProjectLicense(this.licenseId)
      .pipe(
        catchError((error) => {
          this.isExtendProcess = false;
          this.errorMessage =
            error?.error?.detailedMessage ||
            'Failed to extend license, please try again or contact our support';
          this.changeDetectorRef.detectChanges();
          return throwError(() => error);
        }),
        takeUntil(this.hubDestroyService.destroy$),
      )
      .subscribe(() => {
        this.toastService.show('The expiration date has been successfully extended.');
        this.activeModal.close(true);
      });
  }
}
