import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownValue } from '@shared/components/hub-dropdown-form-field/hub-dropdown';

@Component({
  selector: 'app-filter-licenses',
  templateUrl: './filter-licenses.component.html',
  styleUrl: './filter-licenses.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FilterLicensesComponent {
  @Input({
    required: true,
    transform: (value: string[]) => value.map((project) => ({ label: project, value: project })),
  })
  projects: DropdownValue[] = [];

  @Input({
    required: true,
    transform: (value: string[]) => value.map((issuer) => ({ label: issuer, value: issuer })),
  })
  issuers: DropdownValue[] = [];

  @Output() selectValue = new EventEmitter<{
    option: 'project' | 'issuer';
    value: string | boolean | number;
  }>();

  issuersList: DropdownValue[] = [];

  selectedOption: 'project' | 'issuer' = 'project';

  options: DropdownValue[] = [
    {
      label: 'Project Name',
      value: 'project',
    },
    {
      label: 'Issuer',
      value: 'issuer',
    },
  ];

  selectedValue: string | boolean | number = '';

  onSelectValue(): void {
    this.selectValue.emit({ option: this.selectedOption, value: this.selectedValue });
  }

  onChangeOption() {
    this.selectedValue = '';
    this.onChangeValue();
  }

  onChangeValue(value?: string) {
    if (value !== undefined) {
      this.selectedValue = value;
    }
    this.selectValue.emit({ option: this.selectedOption, value: this.selectedValue });
  }
}
