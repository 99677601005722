import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HubDestroyService } from '@core/services/hub-destroy/hub-destroy.service';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ENTITY_NAME_WITH_UPPER_CASE_REG_EXP } from '@core/hubconfig';
import { patternValidator } from '@shared/validators/patternValidator';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastsService } from '@core/services/toasts/toasts.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { LabelsService } from '@core/services/labels/labels.service';
import { HubLabel } from '@core/interfaces/label';
import { HubButtonModule } from '@shared/components/hub-button/hub-button.module';
import { HubInputFormControlModule } from '@shared/components/hub-input-form-control/hub-input-form-control.module';
import { FocusFieldModule } from '@shared/directives/focus-field/focus-field.module';
import { lightColorValidator } from '@shared/validators/light-color.validator';

@Component({
  selector: 'app-create-label-modal',
  templateUrl: './label-editor-modal.component.html',
  styleUrl: './label-editor-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HubDestroyService],
  imports: [HubButtonModule, HubInputFormControlModule, ReactiveFormsModule, FocusFieldModule],
})
export class LabelEditorModalComponent {
  nameMaxLength = 16;
  colorRegExp = /^#([0-9a-f]{3}|[0-9a-f]{6})$/;
  editLabel?: HubLabel;

  formGroup = new FormGroup({
    name: new FormControl<string>(this.editLabel?.name || '', {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.maxLength(this.nameMaxLength),
        Validators.minLength(3),
        patternValidator(ENTITY_NAME_WITH_UPPER_CASE_REG_EXP, { invalidName: true }),
      ],
    }),
    color: new FormControl<string>(this.editLabel?.color || '#4a5fce', {
      nonNullable: true,
      validators: [
        Validators.required,
        patternValidator(this.colorRegExp, { invalidColor: true }),
        lightColorValidator,
      ],
    }),
  });

  constructor(
    public activeModal: NgbActiveModal,
    private readonly toastService: ToastsService,
    private readonly hubDestroyService: HubDestroyService,
    private readonly labelsService: LabelsService,
  ) {}

  saveOrUpdate() {
    if (!this.formGroup.valid) {
      return;
    }

    const labelData = this.formGroup.getRawValue() as Partial<HubLabel>;

    if (this.editLabel) {
      this.labelsService
        .update(labelData, this.editLabel.id)
        .pipe(
          catchError((error) => {
            this.handleLabelServiceError(error.status);
            return throwError(error);
          }),
          this.hubDestroyService.takeUntilDestroy(),
        )
        .subscribe(() => {
          this.activeModal.close(labelData);
        });
    } else {
      this.labelsService
        .create(labelData)
        .pipe(
          catchError((error) => {
            this.handleLabelServiceError(error.status);
            return throwError(error);
          }),
          this.hubDestroyService.takeUntilDestroy(),
        )
        .subscribe((label) => {
          this.activeModal.close(label);
        });
    }
  }

  private handleLabelServiceError(errorStatus: number): void {
    if (errorStatus === 409) {
      this.formGroup.get('name')?.setErrors({ nameUnique: true });
    } else {
      this.toastService.show('Something went wrong, try again later.');
    }
  }
}
