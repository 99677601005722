import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  Agent,
  AgentConnectivityState,
  AgentStatus,
  DeployedAgentResponse,
  HubDesignerAgent,
} from '@core/interfaces/agent';
import { HubSortType } from '@core/interfaces/hub-sort-type';
import { DeploymentStatus } from '@core/interfaces/project';
import { AgentsService } from '@core/services/agents/agents.service';
import { HubDestroyService } from '@core/services/hub-destroy/hub-destroy.service';
import { MethodsService } from '@core/services/methods/methods.service';
import { SettingsService } from '@core/services/settings/settings.service';
import { ToastsService } from '@core/services/toasts/toasts.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionMenu } from '@shared/components/hub-actions-menu/hub-actions-menu';
import { ModalAction } from '@shared/components/hub-entity-action-modal/hub-entity-action-modal';
import { HubEntityActionModalComponent } from '@shared/components/hub-entity-action-modal/hub-entity-action-modal.component';
import { TableSortHeaderCell } from '@shared/atoms/hub-table-header/hub-table-header.component';
import {
  catchError,
  concatMap,
  EMPTY,
  finalize,
  forkJoin,
  Observable,
  of,
  switchMap,
  takeUntil,
  tap,
  throwError,
  timer,
} from 'rxjs';
import { AddAgentModalComponent } from './add-agent-modal/add-agent-modal.component';
import { GENERIC_ERROR_MESSAGE } from '@core/hubconfig';

@Component({
  selector: 'app-agents',
  templateUrl: './agents.component.html',
  styleUrl: './agents.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MethodsService, HubDestroyService],
  standalone: false,
})
export class AgentsComponent implements OnInit {
  AgentStatus = AgentStatus;

  AgentConnectivityState = AgentConnectivityState;

  headerCells: TableSortHeaderCell[] = [
    {
      label: 'Name',
      sortTypeAsc: HubSortType.NAME_ASC,
      sortTypeDesc: HubSortType.NAME_DESC,
      isSortable: true,
    },
    { label: 'Description' },
    { label: 'Environment' },
    { label: 'Status' },
    { label: 'Backend Connection' },
    { label: 'URL' },
    { label: '' },
  ];

  ActionMenu = ActionMenu;

  selectedSortType = HubSortType.NAME_ASC;

  agents: Agent[] = [];

  selectedHubDesignerAgent = HubDesignerAgent.NONE;

  hubDesignerAgentsOptions: string[] = Object.keys(HubDesignerAgent);

  private lastDeploymentResponse: DeployedAgentResponse | undefined;

  private readonly enableAgentSettingKey = 'enableAgent';

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private modalService: NgbModal,
    private agentService: AgentsService,
    private toastService: ToastsService,
    private settingsService: SettingsService,
    private readonly hubDestroyService: HubDestroyService,
  ) {}

  ngOnInit(): void {
    forkJoin([this.agentService.get(), this.settingsService.readOne(this.enableAgentSettingKey)])
      .pipe(takeUntil(this.hubDestroyService.destroy$))
      .subscribe(([agents, enableAgentSetting]) => {
        this.agents = agents.elements.map((agent) => {
          agent.status = agent.isDeployed ? AgentStatus.DEPLOYED : AgentStatus.UN_DEPLOYED;
          agent.showFullDescription = false;
          agent.connectivity = agent.connectivity
            ? agent.connectivity
            : {
                timestamp: undefined,
                state: undefined,
              };
          return agent;
        });
        const defaultAgent = this.agents.find((agent) => agent.isDefault);
        this.selectedHubDesignerAgent =
          defaultAgent && enableAgentSetting.value == 'true'
            ? (defaultAgent.deploymentEnv as HubDesignerAgent)
            : HubDesignerAgent.NONE;
        this.changeDetectorRef.markForCheck();
      });
  }

  onSort(sortOption: HubSortType): void {
    this.selectedSortType = sortOption;
    let property = 'name';
    let order = -1; // DESC
    switch (sortOption) {
      case HubSortType.NAME_ASC: {
        order = 1;
        property = 'name';
        break;
      }
      case HubSortType.NAME_DESC: {
        order = -1;
        property = 'name';
        break;
      }
    }

    this.agents = this.agents.sort(
      (agent1, agent2) =>
        order * (agent1[property as keyof Agent] > agent2[property as keyof Agent] ? 1 : -1),
    );

    this.changeDetectorRef.markForCheck();
  }

  onAddNewAgent(): void {
    const modalRef = this.modalService.open(AddAgentModalComponent, { centered: true });
    modalRef.componentInstance.agents = this.agents;

    modalRef.closed.pipe(takeUntil(this.hubDestroyService.destroy$)).subscribe((newAgent) => {
      newAgent.showFullDescription = false;
      newAgent.connectivity = {
        timestamp: undefined,
        state: undefined,
      };
      newAgent.status = AgentStatus.UN_DEPLOYED;
      this.agents.push(newAgent);
      this.agentService.setAgents(this.agents);
      this.toastService.show('Agent was added successfully');
      this.changeDetectorRef.markForCheck();
    });
  }

  onSelectedActionInMenu(selectedAction: ActionMenu, agent: Agent): void {
    switch (selectedAction) {
      case ActionMenu.DEPLOY_AGENT:
        this.onDeployAgent(agent);
        break;
      case ActionMenu.DELETE:
        this.onDeleteAgent(agent);
        break;
      case ActionMenu.TEST_CONNECTIVITY:
        this.onTestConnectivity(agent);
        break;
    }
  }

  onTestConnectivity(selectedAgent: Agent): void {
    const agent = this.agents.find(({ name }) => name === selectedAgent.name);

    if (agent) {
      agent.connectivity.state = AgentConnectivityState.PENDING;
      this.agentService
        .testConnectivity(agent.id)
        .pipe(
          catchError((err) => {
            agent.connectivity.state = AgentConnectivityState.ERROR;
            const errorMessageToast = GENERIC_ERROR_MESSAGE;
            this.toastService.show(errorMessageToast, 10000);
            throw err;
          }),
          takeUntil(this.hubDestroyService.destroy$),
          finalize(() => {
            this.changeDetectorRef.markForCheck();
          }),
        )
        .subscribe((connectivityResponse) => {
          agent.connectivity = connectivityResponse;
          if (connectivityResponse.state == AgentConnectivityState.SUCCEED) {
            const successMessage = 'Connection succeeded';
            this.toastService.show(successMessage, 10000);
          } else {
            this.toastService.show('No Connection', 10000);
          }
        });
    }
  }

  onDeployAgent(agent: Agent): void {
    this.getDeploymentLink(agent);
  }

  validateExistingAgentInEnvironmentOrSelected(environmentAgent: string): boolean {
    if (environmentAgent === this.selectedHubDesignerAgent) {
      return true;
    }

    if (environmentAgent === HubDesignerAgent.NONE) {
      return false;
    }
    return !this.agents.some((agent) => agent.deploymentEnv === environmentAgent);
  }

  onDeleteAgent(agent: Agent): void {
    const modalRef = this.modalService.open(HubEntityActionModalComponent, { centered: true });

    const paragraphs = [
      'Once deleted, the agent data cannot be retrieved. Are you sure you want to delete this agent from the hub?',
    ];

    if (agent.deploymentEnv === this.selectedHubDesignerAgent) {
      paragraphs.push(
        '\nBe aware, after deletion the OL HUB Designer Agent will no longer be used.',
      );
    }

    modalRef.componentInstance.steps = [
      {
        actionsButtons: [
          { action: ModalAction.cancel, label: 'Keep' },
          { action: ModalAction.apply, label: 'Delete Agent' },
        ],
        title:
          'Delete <hub-icon icon="icon-show-target hub-align-middle"></hub-icon> Agent From OL Hub',
        paragraphs,
      },
    ];
    modalRef.closed
      .pipe(
        switchMap((isRemove) => {
          if (isRemove) {
            const removedAgent = this.agents.find(({ name }) => name === agent.name);
            if (removedAgent) {
              removedAgent.status = AgentStatus.DELETE_PENDING;
              this.changeDetectorRef.markForCheck();
              return this.agentService.deleteOne(agent.name).pipe(
                concatMap(() => {
                  this.agents = this.agents.filter(({ name }) => name !== agent.name);
                  this.agentService.setAgents(this.agents);
                  if (
                    (this.agents.length === 0 &&
                      this.selectedHubDesignerAgent !== HubDesignerAgent.NONE) ||
                    this.selectedHubDesignerAgent == removedAgent.deploymentEnv
                  ) {
                    this.selectedHubDesignerAgent = HubDesignerAgent.NONE;

                    this.agentService.setAgentOn(
                      this.selectedHubDesignerAgent != HubDesignerAgent.NONE
                        ? this.selectedHubDesignerAgent
                        : undefined,
                    );
                    return this.settingsService.updateOne({
                      key: this.enableAgentSettingKey,
                      value: 'false',
                    });
                  } else {
                    return of(void 0);
                  }
                }),
                takeUntil(this.hubDestroyService.destroy$),
              );
            } else {
              return EMPTY;
            }
          } else {
            return EMPTY;
          }
        }),
        catchError((err) => {
          this.toastService.show(GENERIC_ERROR_MESSAGE, 10000);
          return throwError(err);
        }),
        takeUntil(this.hubDestroyService.destroy$),
      )
      .subscribe(() => {
        this.toastService.show('Agent was deleted', 10000);
        this.changeDetectorRef.markForCheck();
      });
  }

  onSelectedHubDesignerAgent(hubDesignerAgentOption: string): void {
    this.settingsService
      .updateOne({
        key: this.enableAgentSettingKey,
        value: String(hubDesignerAgentOption != HubDesignerAgent.NONE),
      })
      .pipe(
        switchMap(() => {
          const agentToUpdate = this.agents.find(
            (agent) => agent.deploymentEnv == hubDesignerAgentOption,
          );

          this.agentService.setAgentOn(
            hubDesignerAgentOption != HubDesignerAgent.NONE ? agentToUpdate?.name : undefined,
          );
          if (agentToUpdate && hubDesignerAgentOption != HubDesignerAgent.NONE) {
            return this.agentService.updateOne({
              name: agentToUpdate.name,
              description: agentToUpdate.description,
              isDefault: true,
            });
          } else {
            this.selectedHubDesignerAgent = hubDesignerAgentOption as HubDesignerAgent;
            this.changeDetectorRef.detectChanges();
            return EMPTY;
          }
        }),
        takeUntil(this.hubDestroyService.destroy$),
      )
      .subscribe(() => {
        this.selectedHubDesignerAgent = hubDesignerAgentOption as HubDesignerAgent;
        this.changeDetectorRef.detectChanges();
      });
  }

  private getDeploymentLink(agent: Agent): void {
    const deployedAgent = this.agents.find(({ name }) => name === agent.name);
    if (deployedAgent) {
      deployedAgent.status = AgentStatus.DEPLOY_PENDING;
      this.agentService
        .deploy(agent.name)
        .pipe(
          switchMap((deployResponse) => {
            this.lastDeploymentResponse = deployResponse;
            if (this.lastDeploymentResponse.status === DeploymentStatus.PENDING) {
              return this.getDeployedJobStatus(
                agent.name,
                this.lastDeploymentResponse,
                agent.deploymentEnv,
              );
            }
            return of(this.lastDeploymentResponse);
          }),
          catchError((err) => {
            deployedAgent.status = AgentStatus.ERROR;
            this.toastService.show(GENERIC_ERROR_MESSAGE, 10000);
            return throwError(err);
          }),
          finalize(() => {
            if (this.lastDeploymentResponse?.status === DeploymentStatus.COMPLETED) {
              deployedAgent.invokeUrl = this.lastDeploymentResponse.deploymentUrl;
              deployedAgent.status = AgentStatus.DEPLOYED;
              this.toastService.show('Agent was deployed successfully');
            } else if (
              this.lastDeploymentResponse?.status === DeploymentStatus.FAILURE ||
              this.lastDeploymentResponse?.status === DeploymentStatus.PENDING
            ) {
              deployedAgent.status = AgentStatus.ERROR;
              this.toastService.show(GENERIC_ERROR_MESSAGE, 10000);
            }
            deployedAgent.isDeployed = deployedAgent.status == AgentStatus.DEPLOYED;
            this.lastDeploymentResponse = undefined;
            this.changeDetectorRef.markForCheck();
          }),
        )
        .subscribe();
    }
  }

  private getDeployedJobStatus(
    agentName: string,
    deployedProjectResponse: DeployedAgentResponse,
    env: string,
  ): Observable<DeployedAgentResponse> {
    const timeout = timer(180000);
    return this.agentService.getDeployedJobStatus(agentName, deployedProjectResponse.id, env).pipe(
      tap((value) => {
        this.lastDeploymentResponse = value;
      }),
      catchError((err) => throwError(err)),
      takeUntil(timeout),
    );
  }
}
