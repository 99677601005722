<div>
  @if (isLoadedMasterLicenseUsage) {
    <div class="hub-mb-1 hub-d-flex hub-flex-wrap">
      @if (masterLicense) {
        <div
          class="hub-d-flex hub-align-items-center hub-border hub-rounded hub-p-1 hub-mr-1 hub-mb-1">
          <div class="hub-mr-1-5">
            <p class="hub-mb-0-5">
              <span class="hub-fw-bold hub-text-secondary hub-fs-20">{{
                remainingDays >= 0 ? remainingDays : 0
              }}</span>
              <span class="hub-color-border-dash-grey hub-fs-12"> days left</span>
            </p>
            <p class="hub-mb-0 hub-text-dark hub-fs-12">
              Expiration date: {{ masterLicense.expirationDate | date: 'd MMM, yyyy' }}
            </p>

            <div class="hub-d-flex hub-align-items-center hub-mt-0-5">
              <hub-button
                type="textPrimary"
                icon="icon-copy"
                [copyToClipboardText]="isLicensePortal ? masterLicense.key : masterLicense.devKey">
                Copy License
              </hub-button>
              <hub-icon
                class="hub-ml-0-5"
                [ngbTooltip]="
                  isLicensePortal
                    ? 'You can use it in the upgrade license request'
                    : 'You can use it to override the generated Dev license in the service configuration'
                "
                icon="icon-info hub-align-middle">
              </hub-icon>
            </div>
          </div>

          <hub-upgrade-license-button
            *hubIsLicenseActive
            class="hub-mb-0-25"
            (upgraded)="getMasterLicense()"></hub-upgrade-license-button>
        </div>
      }

      @for (item of infoItems; track item.label) {
        <div
          class="hub-d-flex hub-border hub-rounded hub-p-1 hub-mr-1 info-block-width hub-justify-content-between hub-mb-1">
          <div class="hub-d-flex hub-flex-column hub-justify-content-between hub-mr-0-25">
            <p class="hub-mb-0-25 hub-fs-20">
              <span class="hub-fw-bold">{{ item.usage }}</span
              ><span class="hub-color-border-dash-grey"
                >/
                @if (item.limitation > 0) {
                  {{ item.limitation }}
                } @else if (item.limitation === -1) {
                  Unlimited
                }
              </span>
            </p>
            <p class="hub-mt-auto hub-mb-0 hub-fs-12 hub-text-dark">{{ item.label }}</p>
          </div>
          <hub-icon icon="{{ item.icon }} hub-text-lh-1-875"></hub-icon>
        </div>
      }
    </div>
  } @else {
    <div class="hub-my-3 hub-text-center">
      <hub-loading-spinner type="bs"></hub-loading-spinner>
    </div>
  }

  <div>
    <div class="hub-d-flex hub-align-items-center hub-mb-0-5">
      <h4 class="hub-mb-0">Project tag licenses</h4>

      @if (isLicensePortal) {
        <hub-button
          *hubIsLicenseActive
          class="hub-ml-0-5"
          [isDisabled]="selectedLicensesCount === 0 || isDisabledDownloadButtons"
          (clicked)="onDownloadLicenses()">
          Download {{ selectedLicensesCount }} licenses
        </hub-button>
      }
      @if (IS_ENTERPRISE_OR_LITE || isLicensePortal) {
        <hub-button
          *hubIsLicenseActive
          (clicked)="onUploadLicense()"
          class="hub-ml-1 hub-mr-0-25"
          type="textPrimary"
          icon="icon-upload">
          {{ isLicensePortal ? 'Upload License Request' : 'Upload Licenses' }}
        </hub-button>
      }
      @if (!isLicensePortal && IS_ENTERPRISE_OR_LITE) {
        <hub-icon
          icon="icon-info hub-align-middle hub-text-danger"
          ngbTooltip="First you need to go to the License portal and download the license from there">
        </hub-icon>
      }
      <div class="hub-ml-auto hub-mr-3">
        @if (isLoadedLicenses) {
          <app-filter-licenses
            [projects]="uniqueProjectNames"
            [issuers]="uniqueIssuers"
            (selectValue)="onFilter($event)"></app-filter-licenses>
        }
      </div>
    </div>

    @if (isLoadedLicenses) {
      @if (licenses.length > 0) {
        <div class="licences-wrapper hub-overflow-auto">
          <table class="hub-table table table-striped" aria-describedby="users-table">
            <thead>
              <tr hub-table-header [list]="headerCells" (sort)="onSort($event)">
                @if (isLicensePortal) {
                  <th class="before-content" scope="col">
                    <hub-checkbox
                      [disabled]="isDisabledDownloadButtons"
                      [ngModel]="isSelectedAll"
                      (ngModelChange)="toggleSelectedAll()"></hub-checkbox>
                  </th>
                }
              </tr>
            </thead>
            <tbody>
              @for (license of licensesToShow; track license.id) {
                <tr>
                  @if (isLicensePortal) {
                    <td>
                      <hub-checkbox
                        [disabled]="isDisabledDownloadButtons"
                        [(ngModel)]="license.checked"
                        (ngModelChange)="onToggleLicense()"></hub-checkbox>
                    </td>
                  }
                  <td>
                    {{ license.projectName }}
                  </td>
                  <td class="hub-fs-10">
                    @if (license.projectTags[0]) {
                      <span class="hub-mr-0-25 hub-px-0-5 hub-py-0-25 hub-border hub-rounded-pill">
                        V{{ license.projectTags[0] }}
                      </span>
                    }
                    @if (license.projectTags[1]) {
                      <span class="hub-mr-0-25 hub-px-0-5 hub-py-0-25 hub-border hub-rounded-pill">
                        V{{ license.projectTags[1] }}
                      </span>
                    }
                    @if (license.projectTags[2]) {
                      <span
                        [ngbTooltip]="license.tagsString"
                        class="hub-text-secondary hub-px-0-5 hub-py-0-25 hub-border hub-rounded-pill">
                        +{{ license.projectTags.length - 2 }}
                      </span>
                    }
                  </td>
                  <td>
                    <span class="hub-mr-0-5">
                      <hub-icon
                        size="small"
                        icon="hub-align-middle icon-premium hub-premium-color"></hub-icon>
                      {{ license.approvedObjects.PREMIUM_METHODS?.length }}
                    </span>
                    <span>
                      <hub-icon
                        size="small"
                        icon="hub-align-middle icon-method-completed hub-text-secondary"></hub-icon>
                      {{ license.approvedObjects.METHODS?.length }}
                    </span>
                  </td>
                  <td>
                    {{ license.createdAt | date: 'dd-MM-yyyy' : 'UTC' }}
                  </td>
                  <td>
                    {{ license.createdBy }}
                  </td>
                  <td>
                    {{ license.expirationDate | date: 'dd-MM-yyyy' : 'UTC' }}

                    @if (
                      isLoadedMasterLicense &&
                      masterLicense.masterLicenseId !== license.masterLicenseId
                    ) {
                      <ng-container *hubIsLicenseActive>
                        <hub-button
                          *hubIsAdmin
                          customClass="hub-fs-14"
                          icon="icon-extend"
                          type="textSecondary"
                          (clicked)="onExtendProjectLicense(license.id)">
                          Extend
                        </hub-button>
                      </ng-container>
                    }
                  </td>

                  <td>
                    <hub-button
                      [hubCopyToClipboard]="license.key"
                      type="iconPrimary"
                      icon="icon-copy"></hub-button>
                  </td>
                  @if (isLicensePortal) {
                    <td>
                      <hub-button
                        *hubIsLicenseActive
                        icon="icon-download"
                        type="iconPrimary"
                        [isDisabled]="isDisabledDownloadButtons"
                        (clicked)="onDownloadLicenses(license.id)"></hub-button>
                    </td>
                  }
                </tr>
              }
            </tbody>
          </table>
          <div class="hub-d-flex">
            <hub-button class="hub-mx-auto hub-my-1-5" type="link" [appScrollToElement]
              >Back to the Top</hub-button
            >
          </div>
        </div>
      } @else {
        <div class="hub-text-center hub-my-3">
          <p>No licenses found</p>
        </div>
      }
    } @else {
      <div class="hub-my-3 hub-text-center">
        <hub-loading-spinner type="bs"></hub-loading-spinner>
      </div>
    }
  </div>
</div>
