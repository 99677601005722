import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { EMPTY, switchMap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastsService } from '@core/services/toasts/toasts.service';
import { ActionModalHelperService } from '@core/services/action-modal-helper/action-modal-helper.service';
import { LabelsService } from '@core/services/labels/labels.service';
import { HubDestroyService } from '@core/services/hub-destroy/hub-destroy.service';
import { LoadingService } from '@shared/components/hub-loading-spinner/loading.service';
import { HubLabel } from '@core/interfaces/label';
import { LabelEditorModalComponent } from './label-editor-modal/label-editor-modal.component';
import { HubHttpErrorHelperService } from '@core/services/hub-http-error-helper/hub-http-error-helper.service';
import { HubCardModule } from '@shared/components/hub-card/hub-card.module';
import { HubButtonModule } from '@shared/components/hub-button/hub-button.module';
import { HubIconComponent } from '@shared/components/hub-icon/hub-icon.component';
import { ModalAction } from '@shared/components/hub-entity-action-modal/hub-entity-action-modal';
import { select } from '@ngxs/store';
import { UserState } from '@core/store/user/user.state';

@Component({
  selector: 'app-labels-tab',
  templateUrl: './labels-tab.component.html',
  styleUrl: './labels-tab.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HubDestroyService],
  imports: [HubCardModule, HubButtonModule, HubIconComponent],
})
export class LabelsTabComponent implements OnInit {
  isAdminUser = select(UserState.isAdminUser);
  isLoaded = false;
  labelsList: HubLabel[] = [];

  constructor(
    private readonly modalService: NgbModal,
    private readonly toastsService: ToastsService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly actionModalHelperService: ActionModalHelperService,
    private readonly hubDestroyService: HubDestroyService,
    private readonly labelsService: LabelsService,
    private loadingService: LoadingService,
    private hubHttpErrorHelperService: HubHttpErrorHelperService,
  ) {}

  ngOnInit(): void {
    this.loadingService.show();
    this.getLabels();
  }

  getLabels(): void {
    this.labelsService
      .all()
      .pipe(
        catchError((err) => {
          this.labelsList = [];
          this.isLoaded = true;
          this.loadingService.hide();
          this.changeDetectorRef.detectChanges();
          return throwError(err);
        }),
        this.hubDestroyService.takeUntilDestroy(),
      )
      .subscribe((list) => {
        this.labelsList = list.elements;
        this.isLoaded = true;
        this.loadingService.hide();
        this.changeDetectorRef.detectChanges();
      });
  }

  createLabel() {
    const modalRef = this.modalService.open(LabelEditorModalComponent, { centered: true });
    modalRef.closed
      .pipe(this.hubDestroyService.takeUntilDestroy())
      .subscribe((newLabel: HubLabel) => {
        this.labelsList.push(newLabel);
        this.changeDetectorRef.detectChanges();
      });
  }

  updateLabel(label: HubLabel) {
    const modalRef = this.modalService.open(LabelEditorModalComponent, { centered: true });
    modalRef.componentInstance.editLabel = label;
    modalRef.componentInstance.formGroup.get('name').setValue(label.name);
    modalRef.componentInstance.formGroup.get('color').setValue(label.color);
    modalRef.closed
      .pipe(this.hubDestroyService.takeUntilDestroy())
      .subscribe((partialLabel: Partial<HubLabel>) => {
        label.name = partialLabel.name as string;
        label.color = partialLabel.color as string;
        this.changeDetectorRef.detectChanges();
      });
  }

  deleteLabel(label: HubLabel) {
    const modalRef = this.actionModalHelperService.confirmPopup([
      {
        actionsButtons: [
          { action: ModalAction.cancel, label: 'Keep' },
          { action: ModalAction.apply, label: 'Remove Label', actionClass: 'btn-danger' },
        ],
        title: `Do you want to delete this label?`,
        paragraphs: [
          'Deleting this label, will disassociate it from all projects, modules, and methods using this label\nAre you sure you want to delete this Label?',
        ],
      },
    ]);

    modalRef.closed
      .pipe(
        switchMap((confirmed) => (confirmed ? this.labelsService.delete(label.id) : EMPTY)),
        this.hubHttpErrorHelperService.displayErrorToast(),
      )
      .subscribe(() => {
        this.toastsService.show('Label has been removed');
        this.labelsList = this.labelsList.filter((listLabel) => listLabel.id !== label.id);
        this.changeDetectorRef.detectChanges();
      });
  }
}
