<div class="modal-header">
  <h4 class="hub-tt-capitalize hub-ff-krub hub-fw-bold hub-fs-20">create new agent</h4>
</div>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <hub-input-form-control
      formControlName="name"
      label="Agent name"
      [isRequired]="true"
      [hasMaxLengthCounter]="true"
      [maxLength]="nameMaxLength"
      [validationMessages]="{
        invalidName: 'Agent name can only contain letters, numbers or hyphens; no whitespace',
        required: 'Agent name is required',
        nameIsNotUnique: 'Please enter unique agent name',
        custom: errMessage,
      }"
      [hint]="agentNameValidationMessage">
    </hub-input-form-control>

    <hub-dropdown-form-field
      formControlName="environmentType"
      label="Environment"
      [required]="true"
      [clearable]="false"
      [options]="hubAvailableEnvironments"></hub-dropdown-form-field>

    <hub-textarea-form-field
      formControlName="description"
      label="Description"></hub-textarea-form-field>
  </div>

  <div class="modal-footer hub-d-flex hub-justify-content-end">
    <hub-button class="hub-mr-1" type="primaryOutline" (clicked)="onCancel()">Cancel</hub-button>
    <hub-button buttonType="submit" [isDisabled]="!form.valid" [isLoading]="!isLoaded">
      Create Agent
    </hub-button>
  </div>
</form>
