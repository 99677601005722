import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { throwError } from 'rxjs';
import { patternValidator } from '@shared/validators/patternValidator';
import { ENTITY_NAME_REG_EXP, ENTITY_NAME_VALIDATION_MESSAGE } from '@core/hubconfig';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AgentsService } from '@core/services/agents/agents.service';
import { HubRuntimeEnvironmentType } from '@core/interfaces/runtime';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { Agent } from '@core/interfaces/agent';
import { HubDestroyService } from '@core/services/hub-destroy/hub-destroy.service';

@Component({
  selector: 'app-add-agent-modal',
  templateUrl: './add-agent-modal.component.html',
  styleUrls: ['./add-agent-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HubDestroyService],
  standalone: false,
})
export class AddAgentModalComponent implements OnInit {
  @Input() agents: Agent[];

  form: UntypedFormGroup;

  descriptionMaxLength: number;

  hubAvailableEnvironments: { value: string; label: string }[];

  readonly nameMaxLength: number;

  errMessage: string;

  isLoaded: boolean;

  protected readonly agentNameValidationMessage = ENTITY_NAME_VALIDATION_MESSAGE.replace(
    '[ENTITY]',
    'agent',
  );

  constructor(
    private activeModal: NgbActiveModal,
    private changeDetectorRef: ChangeDetectorRef,
    private agentsService: AgentsService,
    private hubDestroyService: HubDestroyService,
  ) {
    this.isLoaded = true;
    this.agents = [];
    this.errMessage = '';
    this.nameMaxLength = 50;
    this.descriptionMaxLength = 300;
    this.hubAvailableEnvironments = [];

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(this.nameMaxLength),
        patternValidator(ENTITY_NAME_REG_EXP, { invalidName: true }),
      ]),
      environmentType: new UntypedFormControl(null, [Validators.required]),
      description: new UntypedFormControl('', [Validators.maxLength(this.descriptionMaxLength)]),
    });
  }

  ngOnInit(): void {
    this.constructAvailableEnvironments();
  }

  onSubmit() {
    this.errMessage = '';
    if (this.form.valid) {
      this.isLoaded = false;
      this.agentsService
        .create(this.form.value)
        .pipe(
          finalize(() => {
            this.changeDetectorRef.detectChanges();
          }),
          catchError((err) => {
            if (err.status === 409) {
              this.form.controls.name.setErrors({ nameIsNotUnique: true });
            } else {
              this.errMessage = err?.error?.detailedMessage;
              this.form.controls.name.setErrors({ custom: true });
            }
            this.isLoaded = true;
            return throwError(err);
          }),
          takeUntil(this.hubDestroyService.destroy$),
        )
        .subscribe((newAgent) => {
          this.isLoaded = true;
          this.activeModal.close(newAgent);
        });
    }
  }

  onCancel(): void {
    this.activeModal.dismiss();
  }

  private constructAvailableEnvironments() {
    const isTestAgentExists = this.agents.some(
      (agent) => agent.deploymentEnv == HubRuntimeEnvironmentType.TEST,
    );
    const isProdAgentExists = this.agents.some(
      (agent) => agent.deploymentEnv == HubRuntimeEnvironmentType.PROD,
    );

    if (!isTestAgentExists) {
      this.hubAvailableEnvironments.push({
        value: HubRuntimeEnvironmentType.TEST,
        label: HubRuntimeEnvironmentType.TEST,
      });
    }

    if (!isProdAgentExists) {
      this.hubAvailableEnvironments.push({
        value: HubRuntimeEnvironmentType.PROD,
        label: HubRuntimeEnvironmentType.PROD,
      });
    }

    this.changeDetectorRef.detectChanges();
  }
}
