<div>
  <div class="hub-d-flex hub-align-items-center hub-justify-content-end">
    Selected Agent:
    <div ngbDropdown placement="bottom-right" class="hub-ml-0-5">
      <hub-button type="clean" id="selectHubDesignerAgent" ngbDropdownToggle
        >{{ selectedHubDesignerAgent }}
      </hub-button>
      <div ngbDropdownMenu aria-labelledby="selectHubDesignerAgent">
        @for (hubDesignerAgentOption of hubDesignerAgentsOptions; track hubDesignerAgentOption) {
          <button
            ngbDropdownItem
            [disabled]="validateExistingAgentInEnvironmentOrSelected(hubDesignerAgentOption)"
            (click)="onSelectedHubDesignerAgent(hubDesignerAgentOption)">
            {{ hubDesignerAgentOption }}
          </button>
        }
      </div>
    </div>
  </div>
  <div class="hub-d-flex hub-align-items-center hub-justify-content-between hub-mb-1">
    <h1 class="hub-d-flex hub-align-items-center hub-text-lighter">
      {{ agents.length }}
      <hub-icon [icon]="'hub-mx-0-5 icon-user'"></hub-icon>
      <span class="hub-fs-16 hub-fw-bold">Agent{{ agents.length > 1 ? 's' : '' }}</span>
    </h1>
    <hub-button (clicked)="onAddNewAgent()" [isDisabled]="agents.length > 1">
      Create New Agent
    </hub-button>
  </div>
  <div class="agents-table">
    <table class="hub-table table table-striped" aria-describedby="agents-table">
      <thead>
        <tr hub-table-header [list]="headerCells" (sort)="onSort($event)"></tr>
      </thead>
      <tbody>
        @for (agent of agents; track agent.id) {
          <tr>
            <td>{{ agent.name }}</td>
            <td>
              <p
                class="hub-m-0 hub-p-0"
                [ngClass]="
                  !agent.showFullDescription
                    ? 'hub-text-of-ellipsis agent-ellipsis-cell'
                    : 'show-text'
                ">
                {{ agent.description }}
              </p>
              @if (agent.description.length > 21) {
                <hub-button
                  type="link"
                  customClass="hub-fs-12 hub-p-0 hub-m-0"
                  (clicked)="agent.showFullDescription = !agent.showFullDescription"
                  >({{ !agent.showFullDescription ? 'expand' : 'shorten' }})
                </hub-button>
              }
            </td>
            <td>{{ agent.deploymentEnv }}</td>
            <td class="hub-text-truncate">
              @switch (agent.status) {
                @case (
                  [
                    AgentStatus.DEPLOY_PENDING.toString(),
                    AgentStatus.DELETE_PENDING.toString(),
                  ].includes(agent.status)
                    ? agent.status
                    : !agent.status
                ) {
                  <span class="hub-text-primary">
                    <span
                      class="spinner-border spinner-border-sm hub-mr-0-25"
                      role="status"
                      aria-hidden="true">
                    </span>
                    {{ agent.status === AgentStatus.DEPLOY_PENDING ? 'Deploying' : 'Deleting' }}...
                  </span>
                }
                @case (AgentStatus.DEPLOYED) {
                  <span class="hub-text-success"> Deployed </span>
                }
                @case (AgentStatus.ERROR) {
                  <span>
                    <hub-icon [icon]="'hub-text-danger icon-warning'"></hub-icon>
                  </span>
                }
              }
            </td>
            <td class="hub-text-truncate">
              @if (agent.connectivity.state === AgentConnectivityState.PENDING) {
                <span class="hub-text-primary">
                  <span
                    class="spinner-border spinner-border-sm hub-mr-0-25"
                    role="status"
                    aria-hidden="true">
                  </span>
                  Checking Connectivity...
                </span>
              } @else {
                @switch (agent.connectivity.state) {
                  @case (AgentConnectivityState.SUCCEED) {
                    <span class="hub-d-flex hub-align-items-center hub-text-success">
                      <hub-icon [icon]="'icon-check hub-mr-0-25'"></hub-icon>
                      {{ agent.connectivity.timestamp | date: 'medium' }}
                    </span>
                  }
                  @case (AgentConnectivityState.FAILED) {
                    <span class="hub-d-flex hub-align-items-center hub-text-danger">
                      <hub-icon [icon]="'icon-x hub-mr-0-25'"></hub-icon>
                      {{ agent.connectivity.timestamp | date: 'medium' }}
                    </span>
                  }
                  @case (AgentConnectivityState.ERROR) {
                    <span class="hub-d-flex hub-align-items-center hub-text-danger">
                      <hub-icon [icon]="'icon-warning hub-mr-0-25'"></hub-icon>
                      Error
                    </span>
                  }
                }
              }
            </td>
            <td>
              <p
                class="hub-align-middle hub-d-inline-block hub-mb-0 agent-ellipsis-cell hub-text-truncate"
                container="body"
                [ngbTooltip]="agent.invokeUrl ? agent.invokeUrl : ''">
                {{ agent.invokeUrl ? agent.invokeUrl : 'N/A' }}
              </p>
              @if (agent.invokeUrl) {
                <hub-button
                  type="iconSecondary"
                  icon="hub-icon-primary icon-copy hub-fs-24"
                  [hubCopyToClipboard]="agent.invokeUrl">
                </hub-button>
              }
            </td>
            <td>
              <hub-actions-menu
                [actions]="[
                  { menu: ActionMenu.EDIT, enable: false },
                  { menu: ActionMenu.DELETE, enable: true },
                  { menu: ActionMenu.TEST_CONNECTIVITY, enable: true },
                  {
                    menu: ActionMenu.DEPLOY_AGENT,
                    enable:
                      agent.status !== AgentStatus.DEPLOYED &&
                      agent.status !== AgentStatus.DEPLOY_PENDING,
                  },
                ]"
                (selectedAction)="onSelectedActionInMenu($event, agent)">
              </hub-actions-menu>
            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>
</div>
