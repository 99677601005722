import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  input,
  OnInit,
} from '@angular/core';
import { select } from '@ngxs/store';
import { UserState } from '@core/store/user/user.state';
import { AppState } from '@core/store/application/application.state';
import { ApplicationMode } from '@core/interfaces/application';
import { IS_HUB_ENTERPRISE_OR_HUB_LITE } from '@core/hubconfig';
import { ActivatedRoute } from '@angular/router';
import { HubDestroyService } from '@core/services/hub-destroy/hub-destroy.service';

export enum AccountSettingsTab {
  licenses = 'licenses',
  teams = 'teams',
  agents = 'agents',
  labels = 'labels',
  webhooks = 'webhooks',
}

@Component({
  selector: 'hub-account-settings',
  standalone: false,
  templateUrl: './account-settings.component.html',
  styleUrl: './account-settings.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HubDestroyService],
})
export class AccountSettingsComponent implements OnInit {
  user = select(UserState.user);
  mode = select(AppState.mode);
  isLicensePortal = input(false);

  activeTabId = AccountSettingsTab.licenses;

  protected readonly IS_ENTERPRISE_OR_LITE = IS_HUB_ENTERPRISE_OR_HUB_LITE;
  protected readonly tabs = AccountSettingsTab;
  protected readonly applicationMode = ApplicationMode;

  private readonly route = inject(ActivatedRoute);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly hubDestroyService = inject(HubDestroyService);

  ngOnInit(): void {
    this.route.queryParams.pipe(this.hubDestroyService.takeUntilDestroy()).subscribe((params) => {
      if (params.tab) {
        this.activeTabId =
          (Object.values(this.tabs) as string[]).indexOf(params.tab) !== -1
            ? params.tab
            : AccountSettingsTab.licenses;
        this.changeDetectorRef.markForCheck();
      }
    });
  }
}
