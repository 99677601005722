import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
  signal,
} from '@angular/core';
import { HubSortType } from '@core/interfaces/hub-sort-type';
import { TenantGroup } from '@core/interfaces/permissions';
import { HubUser, UserRoles, UserRolesTitle, UserTenantsAuth } from '@core/interfaces/user';
import { HubDestroyService } from '@core/services/hub-destroy/hub-destroy.service';
import { PermissionsService } from '@core/services/permissions/permissions.service';
import { ToastsService } from '@core/services/toasts/toasts.service';
import { UsersService } from '@core/services/users/users.service';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { TableSortHeaderCell } from '@shared/atoms/hub-table-header/hub-table-header.component';
import { ActionMenu, ActionsMenuModel } from '@shared/components/hub-actions-menu/hub-actions-menu';
import { catchError, distinctUntilChanged, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { AddGroupToTenantModalComponent } from './add-group-to-tenant-modal/add-group-to-tenant-modal.component';
import { AddUserToTenantAccountModalComponent } from './add-user-to-tenant-account-modal/add-user-to-tenant-account-modal.component';
import { EditGroupComponent } from './edit-group/edit-group.component';
import { EditUserModalComponent } from './edit-user-modal/edit-user-modal.component';
import { ManageUsersInGroupComponent } from './manage-users-in-group/manage-users-in-group.component';
import { ResetUserPasswordModalComponent } from './reset-user-password-modal/reset-user-password-modal.component';
import { ModalAction } from '@shared/components/hub-entity-action-modal/hub-entity-action-modal';
import { EMPTY, throwError } from 'rxjs';
import { TenantsStoreService } from '@core/services/tenants/tenants-store.service';
import { filterDefined } from '@core/utils/filter-defined';
import { Tenant } from '@core/interfaces/tenants';
import { IS_HUB_ENTERPRISE_OR_HUB_LITE } from '@core/hubconfig';
import { select, Store } from '@ngxs/store';
import { UserState } from '@core/store/user/user.state';
import { ActionModalHelperService } from '@core/services/action-modal-helper/action-modal-helper.service';
import { isEqual } from 'lodash-es';

@Component({
  selector: 'app-team-tab',
  templateUrl: './team-tab.component.html',
  styleUrls: ['./team-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HubDestroyService],
  standalone: false,
})
export class TeamTabComponent implements OnInit {
  user = select(UserState.user);
  isAdminUser = select(UserState.isAdminUser);

  activeTab: 'members' | 'groups' = 'members';

  usersList = signal<UserTenantsAuth[]>([]);
  selectedSortType = HubSortType.NAME_ASC;
  tenantGroups: TenantGroup[] = [];
  isLicensePortal = false;
  isPullingProcess = false;

  memberHeaders: TableSortHeaderCell[] = [
    {
      label: 'Name',
      sortTypeAsc: HubSortType.NAME_ASC,
      sortTypeDesc: HubSortType.NAME_DESC,
      isSortable: true,
    },
    {
      label: 'Email',
      sortTypeAsc: HubSortType.EMAIL_ASC,
      sortTypeDesc: HubSortType.EMAIL_DESC,
      isSortable: true,
    },
    {
      label: 'Role',
      sortTypeAsc: HubSortType.ROLE_ASC,
      sortTypeDesc: HubSortType.ROLE_DESC,
      isSortable: true,
    },
    { label: '' },
  ];

  groupHeader: TableSortHeaderCell[] = [
    {
      label: 'Name',
      sortTypeAsc: HubSortType.NAME_ASC,
      sortTypeDesc: HubSortType.NAME_DESC,
      isSortable: true,
    },
    { label: 'Description', isSortable: false },
    { label: 'Last Update', isSortable: false },
    { label: '' },
  ];

  private isSSO = false;

  memberActions: ActionsMenuModel[] = [
    { menu: ActionMenu.USER_EDIT, enable: true },
    {
      menu: ActionMenu.USER_RESET_PASSWORD,
      enable: !this.isAdminUser() && !this.isSSO,
    },
    { menu: ActionMenu.REMOVE_USER, enable: true },
  ];

  adminActions: ActionsMenuModel[] = this.memberActions.map((action) =>
    action.menu === ActionMenu.USER_RESET_PASSWORD ? { ...action, enable: false } : action,
  );

  groupActions = [
    { menu: ActionMenu.EDIT, enable: true },
    { menu: ActionMenu.EDIT_USERS_IN_GROUP, enable: true },
    { menu: ActionMenu.DELETE, enable: true },
  ];

  private isPaginationEnd = false;
  private currentPage = 0;

  protected readonly IS_ENTERPRISE_OR_LITE = IS_HUB_ENTERPRISE_OR_HUB_LITE;

  private readonly actionModalHelperService = inject(ActionModalHelperService);
  private readonly store = inject(Store);

  constructor(
    private readonly config: NgbModalConfig,
    private usersService: UsersService,
    private modalService: NgbModal,
    private toastService: ToastsService,
    private changeDetectorRef: ChangeDetectorRef,
    private permissionsService: PermissionsService,
    private readonly hubDestroyService: HubDestroyService,
    private readonly tenantsStoreService: TenantsStoreService,
  ) {
    this.config.centered = true;
    this.config.size = 'lg';
  }

  ngOnInit(): void {
    this.fetchUsers(0);
    this.getGroups();

    this.store
      .select(({ UserContextState }) => UserContextState.user)
      .pipe(
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        this.hubDestroyService.takeUntilDestroy(),
      )
      .subscribe((user: HubUser) => {
        this.usersList.update((users) =>
          users.map((userItem) =>
            userItem.id === user.auth0Id
              ? {
                  ...userItem,
                  firstName: user.firstName,
                  lastName: user.lastName,
                  fullName: `${user.firstName} ${user.lastName}`,
                }
              : userItem,
          ),
        );
      });
    this.tenantsStoreService.isLicensePortal$
      .pipe(
        filterDefined<boolean>(),
        distinctUntilChanged(),
        this.hubDestroyService.takeUntilDestroy(),
      )
      .subscribe((isLicensePortal) => {
        this.isLicensePortal = isLicensePortal;
        this.changeDetectorRef.detectChanges();
      });
    this.tenantsStoreService.tenant$
      .pipe(
        filterDefined<Tenant>(),
        distinctUntilChanged(),
        this.hubDestroyService.takeUntilDestroy(),
      )
      .subscribe((tenant) => {
        this.isSSO = tenant.isSSO;
        this.memberActions = [
          { menu: ActionMenu.USER_EDIT, enable: true },
          {
            menu: ActionMenu.USER_RESET_PASSWORD,
            enable: this.user().roles?.[0] !== UserRoles.ADMIN && !this.isSSO,
          },
          { menu: ActionMenu.REMOVE_USER, enable: true },
        ];
      });
  }

  onPullExternalUsers(): void {
    this.isPullingProcess = true;
    this.usersService
      .pullExternalUsers()
      .pipe(
        catchError((err) => {
          this.toastService.show(
            err.error.detailedMessage ||
              'Could not pull users. Please contact support or try again later',
          );
          this.isPullingProcess = false;
          this.changeDetectorRef.markForCheck();
          return throwError(() => err);
        }),
        takeUntil(this.hubDestroyService.destroy$),
      )
      .subscribe(() => {
        this.fetchUsers(0);
        this.toastService.show('Users pulled successfully');
        this.isPullingProcess = false;
        this.changeDetectorRef.markForCheck();
      });
  }

  onScroll(): void {
    if (!this.isPaginationEnd) {
      this.fetchUsers(this.currentPage);
    }
  }

  openUserModal(): void {
    const modalRef = this.modalService.open(AddUserToTenantAccountModalComponent);

    modalRef.componentInstance.isInvite = !IS_HUB_ENTERPRISE_OR_HUB_LITE;
    modalRef.componentInstance.isSSO = this.isSSO;

    modalRef.closed
      .pipe(take(1), takeUntil(this.hubDestroyService.destroy$))
      .subscribe((newTenantUser) => {
        this.usersList.update((users) => {
          users.unshift(this.mapUserTenant(newTenantUser));
          return users;
        });
        this.toastService.show(
          `User ${IS_HUB_ENTERPRISE_OR_HUB_LITE ? 'added' : 'invited'} successfully`,
        );
        this.changeDetectorRef.markForCheck();
      });
  }

  onAddGroup() {
    const modalRef = this.modalService.open(AddGroupToTenantModalComponent);

    modalRef.componentInstance.tenantUsers = this.usersList().map((user) => ({
      value: user.id,
      label: `${user.firstName} ${user.lastName}`,
    }));

    modalRef.closed.pipe(take(1), takeUntil(this.hubDestroyService.destroy$)).subscribe(() => {
      this.tenantGroups = [];
      this.getGroups();
      this.toastService.show('Group added successfully');
    });
  }

  onSort(sortOption: HubSortType): void {
    this.selectedSortType = sortOption;
    let property = 'name';
    let order = -1; // DESC

    // TODO: implement sort in backend side when backend will be ready
    switch (sortOption) {
      case HubSortType.EMAIL_ASC: {
        order = 1;
        property = 'email';
        break;
      }
      case HubSortType.EMAIL_DESC: {
        order = -1;
        property = 'email';
        break;
      }
      case HubSortType.ROLE_ASC: {
        order = 1;
        property = 'role';
        break;
      }
      case HubSortType.ROLE_DESC: {
        order = -1;
        property = 'role';
        break;
      }
      case HubSortType.NAME_ASC:
      case HubSortType.NAME_DESC: {
        if (this.activeTab === 'members') {
          this.fetchUsers(0, sortOption);
          return;
        } else {
          order = sortOption === HubSortType.NAME_DESC ? -1 : 1;
        }
      }
    }

    let listToSort = this.activeTab === 'members' ? this.usersList() : this.tenantGroups;

    listToSort = listToSort.sort(
      (user1, user2) => order * (user1[property] > user2[property] ? 1 : -1),
    );

    if (this.activeTab === 'members') {
      this.usersList.set(listToSort as UserTenantsAuth[]);
    } else {
      this.tenantGroups = listToSort as TenantGroup[];
    }
    this.changeDetectorRef.markForCheck();
  }

  onSelectedActionInMenu(action: ActionMenu, userInAccount: UserTenantsAuth) {
    if (action === ActionMenu.USER_RESET_PASSWORD) {
      this.openResetPasswordModal(userInAccount);
    } else if (action === ActionMenu.USER_EDIT) {
      this.openEditUserModal(userInAccount);
    } else if (action == ActionMenu.REMOVE_USER) {
      this.openDeleteUserModal(userInAccount);
    }
  }

  onSelectedGroupActionInMenu(action: ActionMenu, group: TenantGroup) {
    if (action === ActionMenu.DELETE) {
      this.deleteGroup(group.id);
    } else if (action === ActionMenu.EDIT) {
      this.onEditGroup(group);
    } else if (action === ActionMenu.EDIT_USERS_IN_GROUP) {
      this.onEditGroupUsers(group);
    }
  }

  onChangeTab(tab: 'members' | 'groups') {
    this.activeTab = tab;
  }

  private onEditGroupUsers(group: TenantGroup) {
    const modalRef = this.modalService.open(ManageUsersInGroupComponent);

    modalRef.componentInstance.group = group;
    modalRef.componentInstance.tenantUsers = this.usersList();
  }

  private onEditGroup(group: TenantGroup) {
    const modalRef = this.modalService.open(EditGroupComponent);

    modalRef.componentInstance.group = group;

    modalRef.closed.pipe(takeUntil(this.hubDestroyService.destroy$)).subscribe((updatedGroup) => {
      const groupInd = this.tenantGroups.findIndex((localGroup) => group.id === localGroup.id);
      this.tenantGroups[groupInd] = {
        ...this.tenantGroups[groupInd],
        ...{
          name: updatedGroup.name,
          description: updatedGroup.description,
          modifiedAt: new Date().toDateString(),
        },
      };
      this.tenantGroups = [...this.tenantGroups];
      this.changeDetectorRef.detectChanges();
    });
  }

  private deleteGroup(groupId: string) {
    this.permissionsService
      .deleteGroup(groupId)
      .pipe(takeUntil(this.hubDestroyService.destroy$))
      .subscribe(() => {
        this.tenantGroups = this.tenantGroups.filter((group) => group.id !== groupId);
        this.toastService.show('Group removed successfully');
        this.changeDetectorRef.markForCheck();
      });
  }

  private getGroups() {
    this.permissionsService
      .getAllGroups()
      .pipe(takeUntil(this.hubDestroyService.destroy$))
      .subscribe((groups) => {
        this.tenantGroups = groups;
        this.changeDetectorRef.markForCheck();
      });
  }

  private fetchUsers(pageNumber: number, sort?: HubSortType) {
    const limit = 100;
    this.usersService
      .all(this.user().tenantId, { pagination: { page: pageNumber, per_page: limit }, sort })
      .pipe(
        map((response) => {
          response = response as UserTenantsAuth[];
          if (response.length < limit) {
            this.isPaginationEnd = true;
          }
          this.currentPage += 1;
          return pageNumber > 0 ? [...this.usersList(), ...response] : [...response];
        }),
        takeUntil(this.hubDestroyService.destroy$),
      )
      .subscribe((usersList) => {
        this.usersList.set(usersList.map((item: UserTenantsAuth) => this.mapUserTenant(item)));
      });
  }

  private openEditUserModal(userInAccount: UserTenantsAuth): void {
    const modalRef = this.modalService.open(EditUserModalComponent);

    modalRef.componentInstance.user = userInAccount;
    modalRef.componentInstance.options = Object.values(UserRoles)
      .filter((value) => value !== userInAccount.role)
      .map((userRole) => ({
        label: userRole,
        value: userRole,
      }));

    modalRef.closed.pipe(takeUntil(this.hubDestroyService.destroy$)).subscribe((updatedUser) => {
      const role = updatedUser.app_metadata.roles[0];
      this.usersList.update((users) =>
        users.map((user) =>
          user.email === updatedUser.email
            ? {
                ...user,
                firstName: updatedUser.given_name,
                lastName: updatedUser.family_name,
                fullName: `${updatedUser.given_name} ${updatedUser.family_name}`,
                role: role?.charAt(0).toUpperCase() + role?.slice(1).toLowerCase(),
                roles: updatedUser.app_metadata.roles,
              }
            : user,
        ),
      );
    });
  }

  private openResetPasswordModal(userInAccount: UserTenantsAuth) {
    const modalRef = this.modalService.open(ResetUserPasswordModalComponent);
    modalRef.componentInstance.userId = userInAccount.id;
    modalRef.componentInstance.userEmail = userInAccount.email;
  }

  private openDeleteUserModal(userInAccount: UserTenantsAuth) {
    const modalRef = this.actionModalHelperService.confirmPopup([
      {
        actionsButtons: [
          { action: ModalAction.cancel, label: 'Keep' },
          { action: ModalAction.apply, label: 'Remove User', actionClass: 'btn-danger' },
        ],
        title: 'Remove User From Tenant',
        paragraphs: [
          `Are you sure you want to remove user ${userInAccount.email} from the tenant?`,
        ],
      },
    ]);
    modalRef.closed
      .pipe(
        switchMap((isRemove) =>
          isRemove ? this.usersService.removeUser(userInAccount.id) : EMPTY,
        ),
        catchError((err) => {
          const errorMessageToast =
            err.error.statusCode == 404 && err.error.detailedMessage == 'Cannot remove yourself'
              ? 'You cannot remove yourself from the tenant'
              : 'Could not remove user. Please contact support or try again later';
          this.toastService.show(errorMessageToast, 10000);
          return throwError(err);
        }),
        takeUntil(this.hubDestroyService.destroy$),
      )
      .subscribe(() => {
        this.toastService.show('User successfully removed', 10000);
        this.usersList.set(this.usersList().filter((user) => user.id !== userInAccount.id));
      });
  }

  private mapUserTenant(item: UserTenantsAuth): UserTenantsAuth {
    return {
      ...item,
      fullName: `${item.firstName} ${item.lastName}`,
      role: UserRolesTitle[item.roles[0]],
    };
  }

  protected readonly UserRoles = UserRoles;
}
