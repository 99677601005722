<form id="hub-add-or-edit-user-form" [formGroup]="userForm">
  <div class="hub-d-flex hub-flex-nowrap">
    <hub-input-form-control
      class="w-50 hub-mr-0-5"
      formControlName="firstName"
      label="First Name"
      [isRequired]="true"
      [validationMessages]="'Please provide first name.'">
    </hub-input-form-control>

    <hub-input-form-control
      class="w-50"
      formControlName="lastName"
      label="Last Name"
      [isRequired]="true"
      [validationMessages]="'Please provide last name.'">
    </hub-input-form-control>
  </div>

  <div class="form-group">
    <label for="user-role-control" class="hub-fw-bold">Role</label>

    <ng-select
      id="user-role-control"
      placeholder="Select role..."
      [items]="HubAvailableRoles"
      bindLabel="title"
      bindValue="value"
      [clearable]="false"
      formControlName="role">
    </ng-select>
  </div>

  <div class="hub-d-flex hub-align-items-start">
    <hub-input-form-control
      class="hub-w-100"
      formControlName="email"
      label="Corporate Email"
      type="email"
      [isRequired]="true"
      [validationMessages]="{
        required: 'Looks like there might be syntax error with your address.',
        email: 'Looks like there might be syntax error with your address.',
        emailAlreadyExists: 'This email address already exists',
      }"></hub-input-form-control>

    <button
      [disabled]="!userForm.get('email')?.value || userForm.get('email')?.invalid"
      type="button"
      class="btn copyBtn hub-p-0 hub-text-left copy-email-btn hub-ml-0-5 hub-fs-14"
      [hubCopyToClipboard]="userForm.get('email')?.value">
      <hub-icon [icon]="'icon-copy hub-align-middle hub-text-primary'"></hub-icon> Copy to clipboard
    </button>
  </div>

  @if (config.state === 'ADD-USER') {
    <hub-password-form-field
      formControlName="password"
      label="Password"
      placeholder="Password"
      [isRequired]="true"
      [allowCopy]="true"
      [allowPasswordMessage]="true"
      [errors]="userForm.get('password')?.errors"
      [dirty]="userForm.get('password')?.dirty">
    </hub-password-form-field>
  }
</form>
