<div class="modal-header">
  <h4 class="hub-tt-capitalize hub-ft-family-krub hub-fw-bold hub-fs-20">
    {{ isUploadLicenseRequestView ? 'Upload license request' : 'Upload license' }}
  </h4>
</div>

<div class="modal-body">
  @if (showErrorMessage) {
    <p class="hub-text-danger">Failed to upload license: please try again or contact our support</p>
  }
  @if (licenseLimitations) {
    <ul>
      <li>
        <b>
          {{ licenseLimitations.METHODS }}
        </b>
        methods
      </li>
      <li>
        <b>
          {{ licenseLimitations.PREMIUM_METHODS }}
        </b>
        premium methods
      </li>
      <li>
        <b>
          {{ licenseLimitations.SCREENS }}
        </b>
        screens
      </li>
    </ul>
  } @else {
    <app-hub-upload-file
      [progress]="loadingProgress"
      [isCancelable]="true"
      (cancelUpload)="onCancelUpload()"
      [fileTypes]="['json']"
      [maxSizeMB]="1"
      (filesChange)="onFileUploaded($event)">
    </app-hub-upload-file>
  }
</div>

<div class="modal-footer hub-d-flex hub-align-items-center">
  @if (licenseLimitations) {
    <p class="hub-fs-14 hub-mb-0 hub-text-danger hub-mr-auto">
      <hub-icon icon="icon-info hub-align-middle"></hub-icon>
      This action is not reversible
    </p>
  }

  <hub-button
    [isDisabled]="isSaveProcess"
    class="hub-mr-1"
    type="primaryOutline"
    (clicked)="onClose()">
    Cancel
  </hub-button>
  @if (licenseLimitations) {
    <hub-button
      type="primary"
      [isDisabled]="isSaveProcess"
      [isLoading]="isSaveProcess"
      (clicked)="onIssueLicense()">
      Continue
    </hub-button>
  } @else {
    <hub-button
      type="primary"
      [isLoading]="isSaveProcess"
      [isDisabled]="!fileContent"
      (clicked)="save()">
      Upload License
    </hub-button>
  }
</div>
