import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HubDestroyService } from '@core/services/hub-destroy/hub-destroy.service';
import { PermissionsService } from '@core/services/permissions/permissions.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { DropdownValue } from '@shared/components/hub-dropdown-form-field/hub-dropdown';

@Component({
  selector: 'app-add-group-to-tenant-modal',
  templateUrl: './add-group-to-tenant-modal.component.html',
  styleUrls: ['./add-group-to-tenant-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HubDestroyService],
  standalone: false,
})
export class AddGroupToTenantModalComponent {
  descriptionMaxLength = 60;

  form = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z\-_[0-9a-zA-Z\-_$]*$/),
    ]),
    description: new FormControl('', [Validators.maxLength(this.descriptionMaxLength)]),
    users: new FormControl([], [Validators.required]),
  });

  tenantUsers: DropdownValue[] = [];
  isLoading = false;
  isUnexpectedGeneralError = false;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly hubDestroyService: HubDestroyService,
    private readonly permissionsService: PermissionsService,
  ) {}

  onCreateGroup() {
    if (this.form.valid) {
      this.isUnexpectedGeneralError = false;
      this.isLoading = true;
      this.permissionsService
        .createGroup(this.form.value as { name: string; description: string; users: string[] })
        .pipe(
          catchError((err) => {
            this.isUnexpectedGeneralError = true;
            this.isLoading = false;
            return throwError(err);
          }),
          takeUntil(this.hubDestroyService.destroy$),
        )
        .subscribe(() => {
          this.activeModal.close();
        });
    }
  }
}
