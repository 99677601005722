<div class="modal-header hub-flex-column hub-mb-3 hub-pb-0">
  <h3 class="modal-title">{{ isInvite ? 'Invite User' : 'Add New User' }}</h3>
  @if (isUnexpectedGeneralError) {
    <p class="hub-text-danger hub-mb-0">
      Failed to {{ isInvite ? 'Invite' : 'Add' }} User. Please contact support&#64;openlegacy.com
    </p>
  }
</div>

<div class="modal-body">
  <app-hub-add-or-edit-user-form
    [IsEmailAlreadyExistsError]="isEmailAlreadyExists"
    [config]="isInvite ? { state: 'INVITE-USER' } : { state: 'ADD-USER' }"
    (formStatusChange)="onUserFormStatusChange($event)">
  </app-hub-add-or-edit-user-form>
</div>

<div class="modal-footer hub-d-flex hub-justify-content-end hub-pt-0 hub-tooltip-mw-15rem">
  <hub-button type="primaryOutline" class="hub-mr-1" (clicked)="activeModal.dismiss()">
    Cancel
  </hub-button>
  <hub-button
    buttonType="submit"
    [isDisabled]="!currentUserFormStatus.isValid || isLoading"
    [ngbTooltip]="!currentUserFormStatus.isValid ? 'Please fill all the fields' : ''"
    [isLoading]="isLoading"
    (clicked)="onSubmit(currentUserFormStatus.isValid, currentUserFormStatus.value)">
    {{ isInvite ? 'Invite' : 'Add' }}
  </hub-button>
</div>
