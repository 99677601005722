<div class="modal-header">
  <h3>
    @if (editLabel) {
      Update Label {{ editLabel.name }}
    } @else {
      Create New Label
    }
  </h3>
</div>
<form [formGroup]="formGroup" (submit)="saveOrUpdate()">
  <div class="modal-body">
    <hub-input-form-control
      hubFocusField
      formControlName="name"
      label="Label Name"
      [maxLength]="nameMaxLength"
      [hasMaxLengthCounter]="true"
      [isRequired]="true"
      [validationMessages]="{
        invalidName: 'Label name can only contain letters, numbers or hyphens; no whitespace',
        required: 'Label name is required',
        maxlength: 'Label name has to be less than ' + nameMaxLength + ' characters',
        minlength: 'Label name has to be at least 3 characters',
        nameUnique: 'Label with such name already exist',
      }"></hub-input-form-control>

    <hub-input-form-control
      formControlName="color"
      label="Label Color"
      type="color"
      [isRequired]="true"
      [validationMessages]="{
        invalidColor: 'Invalid color, only hexadecimal color codes are allowed.',
        lightColor: 'Invalid color, light colors not allowed.',
        required: 'Label color is required',
      }"></hub-input-form-control>
  </div>

  <div class="modal-footer hub-d-flex hub-justify-content-end hub-mt-3">
    <hub-button type="primaryOutline" customClass="hub-mr-1" (clicked)="activeModal.dismiss()">
      Cancel
    </hub-button>
    <hub-button buttonType="submit">
      @if (editLabel) {
        Update
      } @else {
        Create
      }
      Label
    </hub-button>
  </div>
</form>
