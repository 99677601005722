<ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs" [destroyOnHide]="false">
  <li [ngbNavItem]="tabs.licenses">
    <a ngbNavLink class="tab-link">Licenses</a
    ><ng-template ngbNavContent><app-licenses-tab /></ng-template>
  </li>
  <li [ngbNavItem]="tabs.teams">
    <a ngbNavLink class="tab-link">Teams</a
    ><ng-template ngbNavContent><app-team-tab /></ng-template>
  </li>
  @if (!isLicensePortal()) {
    @if (!IS_ENTERPRISE_OR_LITE) {
      <li [ngbNavItem]="tabs.agents">
        <a ngbNavLink class="tab-link">Agents</a
        ><ng-template ngbNavContent><app-agents /></ng-template>
      </li>
    }
    @if (mode() !== applicationMode.mpt) {
      <li [ngbNavItem]="tabs.labels">
        <a ngbNavLink class="tab-link">Labels</a
        ><ng-template ngbNavContent><app-labels-tab /></ng-template>
      </li>
      <li [ngbNavItem]="tabs.webhooks">
        <a ngbNavLink class="tab-link">Webhooks</a
        ><ng-template ngbNavContent><app-webhooks-tab /></ng-template>
      </li>
    }
  }
</ul>

<div [ngbNavOutlet]="nav" class="hub-pt-1"></div>
