import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { HubAuthService } from '@core/services/hub-auth/hub-auth.service';
import { HubDestroyService } from '@core/services/hub-destroy/hub-destroy.service';
import { TenantsStoreService } from '@core/services/tenants/tenants-store.service';
import { TenantsService } from '@core/services/tenants/tenants.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { switchMap, Observable, first, catchError, throwError } from 'rxjs';
import { HubActivateLicenseModalComponent } from '../hub-activate-license-modal/hub-activate-license-modal.component';
import { HubButtonModule } from '../hub-button/hub-button.module';
import { ModalAction } from '../hub-entity-action-modal/hub-entity-action-modal';
import { HubEntityActionModalComponent } from '../hub-entity-action-modal/hub-entity-action-modal.component';
import { MasterLicense } from '@core/interfaces/production-license';
import { Auth0UserMetaDataKeys } from '@core/interfaces/user';
import { IS_HUB_ENTERPRISE_OR_HUB_LITE } from '@core/hubconfig';

@Component({
  selector: 'hub-upgrade-license-button',
  imports: [HubButtonModule],
  templateUrl: './hub-upgrade-license-button.component.html',
  styleUrl: './hub-upgrade-license-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HubDestroyService],
})
export class HubUpgradeLicenseButtonComponent {
  @Input() btnLabel = 'Upgrade';

  @Output() upgraded = new EventEmitter<MasterLicense>();

  constructor(
    private hubDestroyService: HubDestroyService,
    private modalService: NgbModal,
    private authService: HubAuthService,
    private tenantsService: TenantsService,
    private tenantsStoreService: TenantsStoreService,
  ) {}

  onUpdate(): void {
    if (IS_HUB_ENTERPRISE_OR_HUB_LITE) {
      this.showLicenseModal();
    } else {
      const modalRef = this.modalService.open(HubEntityActionModalComponent, {
        centered: true,
      });
      modalRef.componentInstance.steps = [
        {
          actionsButtons: [{ action: ModalAction.apply, label: 'Done' }],
          title: 'Upgrade Request',
          paragraphs: [
            'Thanks for reaching out. For more information about upgrading your subscription please contact',
            '<a href="mailto:sales@openlegacy.com">sales@openlegacy.com</a>',
          ],
        },
      ];
    }
  }

  private showLicenseModal(): void {
    const modalRef = this.modalService.open(HubActivateLicenseModalComponent, {
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.componentInstance.upgradeLicense = true;

    modalRef.closed
      .pipe(
        switchMap(() => this.authService.getUser$()),
        switchMap((user) => this.getLicenseInfo(user)),
        this.hubDestroyService.takeUntilDestroy(),
      )
      .subscribe((license) => {
        this.tenantsStoreService.setLicenseState(license);
        this.upgraded.emit(license);
      });
  }

  private getLicenseInfo(user: {
    [Auth0UserMetaDataKeys.TENANT]: string;
  }): Observable<MasterLicense> {
    return this.tenantsService.getLicense(user[Auth0UserMetaDataKeys.TENANT]).pipe(
      first(),
      catchError((err) => {
        this.showLicenseModal();
        this.tenantsStoreService.setLicenseState(undefined);
        return throwError(() => err);
      }),
    );
  }
}
