import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { HubHttpPaginationResponse } from '@core/interfaces/HubResponse';
import { HubSortType } from '@core/interfaces/hub-sort-type';
import {
  MasterLicense,
  MasterLicenseUsage,
  ProductionLicense,
} from '@core/interfaces/production-license';
import { HubDatesHelperService } from '@core/services/hub-dates-helper/hub-dates-helper.service';
import { HubDestroyService } from '@core/services/hub-destroy/hub-destroy.service';
import { HubDownloadService } from '@core/services/hub-download/hub-download.service';
import { LicensesService } from '@core/services/licenses/licenses.service';
import { TenantsStoreService } from '@core/services/tenants/tenants-store.service';
import { ToastsService } from '@core/services/toasts/toasts.service';
import { filterDefined } from '@core/utils/filter-defined';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TableSortHeaderCell } from '@shared/atoms/hub-table-header/hub-table-header.component';
import {
  catchError,
  distinctUntilChanged,
  mergeMap,
  Observable,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';
import { ExtendProjectLicenseComponent } from './extend-project-license/extend-project-license.component';
import { UploadLicenseComponent } from './upload-license/upload-license.component';
import { IS_HUB_ENTERPRISE_OR_HUB_LITE } from '@core/hubconfig';
import { select } from '@ngxs/store';
import { UserState } from '@core/store/user/user.state';

interface InfoItem {
  label: string;
  usage: number;
  limitation: number;
  icon: string;
}

@Component({
  selector: 'app-licenses-tab',
  templateUrl: './licenses-tab.component.html',
  styleUrl: './licenses-tab.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HubDestroyService],
  standalone: false,
})
export class LicensesTabComponent implements OnInit {
  user = select(UserState.user);

  isLicensePortal = false;

  licensesToShow: ProductionLicense[] = [];

  licenses: ProductionLicense[] = [];

  masterLicenseUsage!: MasterLicenseUsage;

  masterLicense!: MasterLicense;

  isLoadedMasterLicenseUsage = false;

  isLoadedLicenses = false;

  selectedSortType!: HubSortType;

  uniqueProjectNames: string[] = [];
  uniqueIssuers: string[] = [];

  remainingDays = 0;

  headerCells: TableSortHeaderCell[] = [
    {
      label: 'Project name',
      sortTypeAsc: HubSortType.PROJECT_NAME_ASC,
      sortTypeDesc: HubSortType.PROJECT_NAME_DESC,
      isSortable: true,
    },
    {
      label: 'Tags',
    },
    {
      label: 'Methods',
    },
    {
      label: 'Issue date',
      sortTypeAsc: HubSortType.ISSUE_DATE_ASC,
      sortTypeDesc: HubSortType.ISSUE_DATE_DESC,
      isSortable: true,
    },
    {
      label: 'Issuer',
      sortTypeAsc: HubSortType.NAME_ASC,
      sortTypeDesc: HubSortType.NAME_DESC,
      isSortable: true,
    },
    {
      label: 'Expiry date',
      sortTypeAsc: HubSortType.EXPIRY_DATE_ASC,
      sortTypeDesc: HubSortType.EXPIRY_DATE_DESC,
      isSortable: true,
    },
    {
      label: 'Key',
    },
  ];

  infoItems: InfoItem[] = [];

  isDisabledDownloadButtons = false;

  isSelectedAll = false;

  selectedLicensesCount = 0;

  isLoadedMasterLicense = false;

  protected readonly IS_ENTERPRISE_OR_LITE = IS_HUB_ENTERPRISE_OR_HUB_LITE;

  constructor(
    private licensesService: LicensesService,
    private destroyService: HubDestroyService,
    private changeDetectorRef: ChangeDetectorRef,
    private modalService: NgbModal,
    private datesHelperService: HubDatesHelperService,
    private tenantsStoreService: TenantsStoreService,
    private hubDownloadService: HubDownloadService,
    private toastService: ToastsService,
  ) {}

  ngOnInit(): void {
    this.tenantsStoreService.isLicensePortal$
      .pipe(
        filterDefined<boolean>(),
        distinctUntilChanged(),
        this.destroyService.takeUntilDestroy(),
      )
      .subscribe((isLicensePortal) => {
        this.isLicensePortal = isLicensePortal;
        if (this.isLicensePortal) {
          this.headerCells.push({});
        }
        this.changeDetectorRef.detectChanges();
      });
    this.getLicenses().subscribe();
    this.getMasterLicenseUsage();
    this.getMasterLicense();
  }

  getMasterLicense() {
    this.licensesService
      .getMasterLicense(this.user().tenantId)
      .pipe(takeUntil(this.destroyService.destroy$))
      .subscribe((masterLicense) => {
        this.masterLicense = masterLicense;
        this.remainingDays = this.datesHelperService.differenceInDays(
          new Date(masterLicense.expirationDate),
          new Date(),
        );
        this.isLoadedMasterLicense = true;
        this.changeDetectorRef.detectChanges();
      });
  }

  onExtendProjectLicense(id: string) {
    const modalRef = this.modalService.open(ExtendProjectLicenseComponent, {
      centered: true,
    });

    modalRef.componentInstance.licenseId = id;
    modalRef.componentInstance.licenseLimitations = this.masterLicenseUsage.runtimeLimitations;

    modalRef.closed.pipe(takeUntil(this.destroyService.destroy$)).subscribe((renewed) => {
      if (renewed) {
        this.licensesToShow = this.licensesToShow.map((license) => {
          if (license.id === id) {
            license.masterLicenseId = this.masterLicense.masterLicenseId;
            license.expirationDate = this.masterLicense.expirationDate;
          }
          return license;
        });
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  getLicenses(): Observable<HubHttpPaginationResponse<ProductionLicense>> {
    return this.licensesService.all().pipe(
      catchError((error) => {
        this.isLoadedLicenses = true;
        this.changeDetectorRef.detectChanges();
        return throwError(() => error);
      }),
      tap((licensesResponse) => {
        this.licenses = [...licensesResponse.elements];
        for (const license of this.licenses) {
          license.tagsString = this.getTagsString(license.projectTags);
        }
        this.licensesToShow = [...licensesResponse.elements];
        this.uniqueProjectNames = Array.from(
          new Set(this.licenses.map((license) => license.projectName)),
        );
        this.uniqueIssuers = Array.from(new Set(this.licenses.map((license) => license.createdBy)));
        this.isLoadedLicenses = true;
        this.changeDetectorRef.detectChanges();
      }),
      takeUntil(this.destroyService.destroy$),
    );
  }

  getMasterLicenseUsage() {
    this.licensesService
      .getMasterLicenseUsage(this.user().tenantId)
      .pipe(takeUntil(this.destroyService.destroy$))
      .subscribe((masterLicenseUsage) => {
        this.masterLicenseUsage = masterLicenseUsage;
        this.fillInfoItems();
        this.isLoadedMasterLicenseUsage = true;
        this.changeDetectorRef.detectChanges();
      });
  }

  onFilter(data: { option: 'project' | 'issuer'; value: string | boolean | number }) {
    const { option, value } = data;
    if (value) {
      this.licensesToShow = this.licenses.filter(
        (license) => license[option === 'project' ? 'projectName' : 'createdBy'] === value,
      );
    } else {
      this.licensesToShow = [...this.licenses];
    }
  }

  onSort(sortOption: HubSortType): void {
    this.selectedSortType = sortOption;
    let property = 'name';
    let order = -1; // DESC
    let isPropertyDate = false;
    switch (sortOption) {
      case HubSortType.PROJECT_NAME_ASC: {
        order = 1;
        property = 'projectName';
        break;
      }
      case HubSortType.PROJECT_NAME_DESC: {
        order = -1;
        property = 'projectName';
        break;
      }
      case HubSortType.ISSUE_DATE_ASC: {
        order = 1;
        property = 'createdAt';
        isPropertyDate = true;
        break;
      }
      case HubSortType.ISSUE_DATE_DESC: {
        order = -1;
        property = 'createdAt';
        isPropertyDate = true;
        break;
      }
      case HubSortType.ISSUER_ASC: {
        order = 1;
        property = 'createdBy';
        break;
      }
      case HubSortType.ISSUER_DESC: {
        order = -1;
        property = 'createdBy';
        break;
      }
      case HubSortType.EXPIRY_DATE_ASC: {
        order = 1;
        property = 'modifiedAt';
        isPropertyDate = true;
        break;
      }
      case HubSortType.EXPIRY_DATE_DESC: {
        order = -1;
        property = 'modifiedAt';
        isPropertyDate = true;
        break;
      }
    }

    this.licensesToShow = this.licensesToShow.sort((license1, license2) => {
      const property1 = isPropertyDate
        ? +new Date(license1[property as keyof ProductionLicense] as Date)
        : license1[property as keyof ProductionLicense] || '';

      const property2 = isPropertyDate
        ? +new Date(license2[property as keyof ProductionLicense] as Date)
        : license2[property as keyof ProductionLicense] || '';

      return order * (property1 > property2 ? 1 : -1);
    });

    this.changeDetectorRef.markForCheck();
  }

  onUploadLicense() {
    const modalRef = this.modalService.open(UploadLicenseComponent, {
      centered: true,
    });

    modalRef.componentInstance.isUploadLicenseRequestView = this.isLicensePortal;

    modalRef.closed
      .pipe(
        mergeMap(() => {
          this.toastService.show(
            'The license has been successfully added to the list of Project tag licenses',
          );
          return this.getLicenses();
        }),
        takeUntil(this.destroyService.destroy$),
      )
      .subscribe();
  }

  toggleSelectedAll() {
    this.isSelectedAll = !this.isSelectedAll;

    this.licensesToShow = this.licensesToShow.map((license) => ({
      ...license,
      checked: this.isSelectedAll,
    }));
    this.selectedLicensesCount = this.licensesToShow.filter((license) => license.checked).length;
  }

  onToggleLicense() {
    this.isSelectedAll = this.licensesToShow.every((license) => license.checked);
    this.selectedLicensesCount = this.licensesToShow.filter((license) => license.checked).length;
  }

  onDownloadLicenses(licenseId?: string): void {
    if (this.isLicensePortal) {
      this.isDisabledDownloadButtons = true;
      const ids = licenseId
        ? [licenseId]
        : this.licensesToShow.filter((license) => license.checked).map((license) => license.id);

      this.licensesService
        .downloadLicenses(ids)
        .pipe(
          catchError((error) => {
            this.toastService.show(
              error?.error?.detailedMessage ||
                'Failed to download, please try again or contact our support',
            );
            this.isDisabledDownloadButtons = false;
            this.changeDetectorRef.detectChanges();
            return throwError(() => error);
          }),
          takeUntil(this.destroyService.destroy$),
        )
        .subscribe((response) => {
          this.isDisabledDownloadButtons = false;
          this.hubDownloadService.download(
            JSON.stringify(response),
            `licenses-${+new Date()}.json`,
          );
          this.changeDetectorRef.detectChanges();
        });
    }
  }

  private getTagsString(tags: number[]): string {
    return tags
      .slice(2)
      .map((tag) => `V${tag}`)
      .join(', ');
  }

  private fillInfoItems(): void {
    this.infoItems.push({
      icon: 'icon-premium-nofilled hub-premium-color',
      label: 'Premium Method Usage',
      usage: this.masterLicenseUsage.usageCounts.PREMIUM_METHODS || 0,
      limitation: this.masterLicenseUsage.runtimeLimitations?.PREMIUM_METHODS || 0,
    });
    this.infoItems.push({
      icon: 'icon-method-completed text-secondary',
      label: 'Method Usage',
      usage: this.masterLicenseUsage.usageCounts.METHODS || 0,
      limitation: this.masterLicenseUsage.runtimeLimitations?.METHODS || 0,
    });
    if (this.masterLicenseUsage.runtimeLimitations?.SCREENS !== 0) {
      this.infoItems.push({
        icon: 'icon-assets hub-color-assets-section',
        label: 'Screens Usage',
        usage: this.masterLicenseUsage.usageCounts.SCREENS || 0,
        limitation: this.masterLicenseUsage.runtimeLimitations?.SCREENS || 0,
      });
    }
    if (this.masterLicenseUsage.runtimeLimitations?.RPC_ASSETS !== 0) {
      this.infoItems.push({
        icon: 'icon-assets hub-color-assets-section',
        label: 'RPC Assets Usage',
        usage: this.masterLicenseUsage.usageCounts.RPC_ASSETS || 0,
        limitation: this.masterLicenseUsage.runtimeLimitations?.RPC_ASSETS || 0,
      });
    }
  }
}
