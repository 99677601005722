<div class="modal-header hub-align-items-center">
  @if (!licenseKeyActivationFailed) {
    <h3 class="hub-mb-0">
      {{ isEnterpriseOrLightMode ? 'Please Activate Your License' : 'License Expired' }}
    </h3>
  } @else {
    <h3>
      <hub-icon [icon]="'hub-align-middle icon-warning hub-text-danger'"></hub-icon>
      License Activation Failed
    </h3>
  }
  <hub-button
    customClass="btn-small"
    type="iconSecondary"
    icon="icon-x"
    (clicked)="onLogout()"></hub-button>
</div>

<form class="form-group" [formGroup]="form" (submit)="onSubmit()">
  @if (isEnterpriseOrLightMode) {
    <div class="modal-body hub-mb-0-25">
      <input type="text" class="form-control" formControlName="licenseKey" />
      @if (!licenseKeyActivationFailed) {
        <small class="form-text hub-text-lighter"> Enter your license to continue </small>
      } @else {
        <div class="invalid-feedback hub-d-block">
          Invalid License Key. Please re-enter or contact support.
        </div>
      }
    </div>
  }

  <div
    class="hub-d-flex hub-justify-content-between"
    [ngClass]="{ 'modal-footer': isEnterpriseOrLightMode }">
    <div>
      <p class="hub-text-lighter hub-mb-0 hub-fw-bold hub-text-lh-150">Need Help?</p>
      <p class="hub-text-lighter hub-mb-0 hub-text-lh-150">
        Contact
        <a
          class="hub-ff-roboto hub-text-lighter hub-fw-normal hub-fs-16"
          href="mailto:support@openlegacy.com">
          support&#64;openlegacy.com
        </a>
      </p>
    </div>

    @if (isEnterpriseOrLightMode) {
      <hub-button buttonType="submit" type="primary" [isDisabled]="form.invalid">
        Activate
      </hub-button>
    }
  </div>
</form>
