<div class="modal-header hub-flex-column hub-mb-3 hub-pb-0">
  <h3 class="hub-mb-0-5">Reset Password For</h3>
  <p class="hub-text-message hub-mb-0">{{ userEmail }}</p>
</div>

<div class="modal-body">
  <div class="hub-d-flex hub-flex-column hub-mb-1">
    <hub-button
      type="clean"
      [isDisabled]="!resetUserPasswordForm.get('password')?.value || resetUserPasswordForm.invalid"
      customClass="hub-fs-14"
      class="hub-p-0 hub-ml-auto"
      icon="icon-copy hub-align-middle {{!resetUserPasswordForm.get('password')?.value || resetUserPasswordForm.invalid
            ? 'hub-text-disabled-grey-color'
            : 'hub-text-primary'}}"
      [copyToClipboardText]="resetUserPasswordForm.get('password')?.value">
      Copy Password
    </hub-button>
  </div>

  <form [formGroup]="resetUserPasswordForm" (ngSubmit)="onSubmit()" class="hub-mb-11rem">
    <hub-password-form-field
      formControlName="password"
      label="Reset Password"
      placeholder="Reset Password"
      ariaDescribedby="passwordHelp"
      [isRequired]="true"
      [allowPasswordMessage]="true"
      [errors]="resetUserPasswordForm.get('password')?.errors"
      [dirty]="resetUserPasswordForm.get('password')?.dirty"
      autocomplete="current-password" />
  </form>
</div>

<div class="modal-footer hub-d-flex hub-justify-content-end hub-pt-0">
  <hub-button
    type="primaryOutline"
    class="hub-mr-1"
    (clicked)="dismissModal()">
    Cancel
  </hub-button>
  <hub-button
    buttonType="submit"
    (clicked)="onSubmit()"
    [isLoading]="isLoading()"
    [isDisabled]="resetUserPasswordForm.invalid || isLoading()">
    Reset Password
  </hub-button>
</div>
