<div class="hub-d-flex hub-align-items-center" (click)="$event.stopPropagation()">
  <div class="hub-mr-0-5">
    <hub-icon icon="hub-align-middle icon-filter"></hub-icon>
    <span class="hub-fs-14"> Filter by </span>
  </div>

  <div
    class="hub-p-relative"
    ngbDropdown
    display="dynamic"
    #optionsDropdown="ngbDropdown"
    id="optionsDropdown">
    <hub-button
      customClass="hub-p-0"
      [customNgClass]="{ 'hub-text-secondary': !!selectedValue }"
      type="clean"
      ngbDropdownToggle>
      {{ selectedOption === 'project' ? 'Project name' : 'Issuer' }}
    </hub-button>

    <div class="dropdown-wrapper hub-p-1" ngbDropdownMenu aria-labelledby="optionsDropdown">
      <div class="hub-mb-1">
        <hub-dropdown-form-field
          [(ngModel)]="selectedOption"
          [options]="options"
          label="Filter by: "
          (ngModelChange)="onChangeOption()">
        </hub-dropdown-form-field>
      </div>

      <div class="hub-mb-1">
        <hub-dropdown-form-field
          [searchable]="true"
          [showSelectedTooltip]="true"
          [showOptionTooltip]="true"
          [(ngModel)]="selectedValue"
          [options]="selectedOption === 'project' ? projects : issuers"
          label="By:"
          (ngModelChange)="onChangeValue($event)">
        </hub-dropdown-form-field>
      </div>

      <hub-button type="primaryOutline" (clicked)="onChangeValue('')">Reset</hub-button>
    </div>
  </div>
</div>
