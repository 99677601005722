<div class="modal-header">
  <h4 class="hub-tt-capitalize hub-ft-family-krub hub-fw-bold hub-fs-20">
    Renew a project tag license
  </h4>
</div>

<div class="modal-body">
  <p>Renewing this license will consume the following from the account license total quota:</p>
  @if (errorMessage) {
    <p class="hub-text-danger">{{ errorMessage }}</p>
  }
  <ul>
    <li>
      <b>
        {{ licenseLimitations?.METHODS === -1 ? 'Unlimited' : licenseLimitations?.METHODS || 0 }}
      </b>
      methods
    </li>
    <li>
      <b>
        {{
          licenseLimitations?.PREMIUM_METHODS === -1
            ? 'Unlimited'
            : licenseLimitations?.PREMIUM_METHODS || 0
        }}
      </b>
      premium methods
    </li>
    <li>
      <b>
        {{ licenseLimitations?.SCREENS === -1 ? 'Unlimited' : licenseLimitations?.SCREENS || 0 }}
      </b>
      screens
    </li>
  </ul>
</div>

<div class="modal-footer hub-d-flex hub-align-items-center">
  <p class="hub-fs-14 hub-mb-0 hub-text-danger hub-mr-auto">
    <hub-icon icon="icon-info hub-align-middle"></hub-icon>
    This action is not reversible
  </p>

  <hub-button
    [isDisabled]="isExtendProcess"
    class="hub-mr-1"
    type="primaryOutline"
    (clicked)="onClose()">
    Cancel
  </hub-button>
  <hub-button [isDisabled]="isExtendProcess" [isLoading]="isExtendProcess" (clicked)="onExtend()">
    Continue
  </hub-button>
</div>
