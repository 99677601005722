import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TenantGroup } from '@core/interfaces/permissions';
import { HubDestroyService } from '@core/services/hub-destroy/hub-destroy.service';
import { PermissionsService } from '@core/services/permissions/permissions.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-edit-group',
  templateUrl: './edit-group.component.html',
  styleUrls: ['./edit-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HubDestroyService],
  standalone: false,
})
export class EditGroupComponent implements OnInit {
  form!: FormGroup;

  isUnexpectedGeneralError: boolean;

  group!: TenantGroup;

  isLoading: boolean;

  descriptionMaxLength: number;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly hubDestroyService: HubDestroyService,
    private permissionsService: PermissionsService,
  ) {
    this.isLoading = false;
    this.isUnexpectedGeneralError = false;
    this.descriptionMaxLength = 60;
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl(this.group.name, [
        Validators.required,
        Validators.pattern(/^[a-zA-Z\-_[0-9a-zA-Z\-_$]*$/),
      ]),
      description: new FormControl(this.group.description, [
        Validators.maxLength(this.descriptionMaxLength),
      ]),
    });
  }

  onEdit() {
    if (this.form.valid) {
      this.isUnexpectedGeneralError = false;
      this.isLoading = true;
      this.permissionsService
        .editGroup(this.group.id, this.form.value)
        .pipe(
          catchError((err) => {
            this.isUnexpectedGeneralError = true;
            this.isLoading = false;
            return throwError(err);
          }),
          takeUntil(this.hubDestroyService.destroy$),
        )
        .subscribe(() => {
          this.activeModal.close(this.form.value);
        });
    }
  }
}
